import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid, Stack } from 'material-latest';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';

const BankTransferCredentialsNab = ({ bankTransferData, handleCopy, t, classes }) => {
  return (
    <>
      <Grid container gap={2}>
        <Grid container gap={2}>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.bankName')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.bankName}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.bankName)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.bankCountry')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.bankCountry}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.bankCountry)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.bankAddress')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.bankAddress}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.bankAddress)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.swift')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.swift}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.swift)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.bsb')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.bsb}
              </Typography>
              <IconButton onClick={handleCopy(bankTransferData.bsb)} className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.accountNumber')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.accountNumber}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.accountNumber)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.accountName')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.accountName}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.accountName)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.accountAddress')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.accountAddress}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.accountAddress)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          <Typography className={classes.termsContainerKey}>
            {t('deposit.bankTransfer.referenceKey')}
          </Typography>
          <Typography className={classes.termsContainerValue}>
            {t('deposit.bankTransfer.referenceValue')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default BankTransferCredentialsNab;
