import eurFlag from 'static/flags/EU.svg';
import audFlag from 'static/flags/Australia.svg';
import usdFlag from 'static/flags/UnitedStates.svg';
import jpyFlag from 'static/flags/Japan.svg';
import gbpFlag from 'static/flags/UK.svg';
import sgdFlag from 'static/flags/Singapore.svg';
import thbFlag from 'static/flags/Thailand.svg';
import cadFlag from 'static/flags/Canada.svg';
import hkdFlag from 'static/flags/HongKong.svg';
import nzdFlag from 'static/flags/NewZealand.svg';
import chfFlag from 'static/flags/Switzerland.svg';
import hufFlag from 'static/flags/Hungary.svg';
import czkFlag from 'static/flags/Czechrepublic.svg';
import dkkFlag from 'static/flags/Denmark.svg';
import plnFlag from 'static/flags/Poland.svg';
import nokFlag from 'static/flags/Norway.svg';
import sekFlag from 'static/flags/Sweden.svg';
import phpFlag from 'static/flags/Philippines.svg';
import cnyFlag from 'static/flags/China.svg';
import myrFlag from 'static/flags/Malaysia.svg';
import idrFlag from 'static/flags/Indonesia.svg';
import vndFlag from 'static/flags/Vietnam.svg';

import { domain } from '../modules/api';
import config from 'config';

const { ACCOUNT_TYPES } = config;

export const DATE_FORMAT_DDMMYYYY = 'DD/MM/YYYY';
export const DATE_FORMAT_YYYYMMDD = 'YYYY-MM-DD';
export const DATE_FORMAT_FULL = 'Do MMMM YYYY';
export const DATE_FORMAT_MMYY = 'MM/YY';
export const DATE_FORMAT_DD_MM_YYYY_HHmmss_z = 'DD-MM-YYYY HH:mm:ss z';
export const DATE_WITH_TIME_FORMAT = 'DD/MM/YYYY hh:mm a';

export const TIME_FORMAT_hhmm_a = 'hh:mm a';

export const DATE_TIME_FORMAT = 'hh:mm a DD/MM/YYYY';

export const MONTH_DAYS_NUMBER = 30;

export const FOLLOWER_ID_COOKIE_NAME = 'followerId';
export const FOLLOWER_HUB_COOKIE_NAME = 'followerHub';
export const REFERRAL_COOKIE_NAME = 'cxd';
export const REFERRAL_COOKIE_HUB = 'cxdHub';
export const GP_REFERRAL_COOKIE_NAME = 'referral';
export const UTM_COOKIE_NAME = 'utm';
export const LANG_COOKIE_NAME = 'lang';
export const POLICY_COOKIE_NAME = 'policy';

export const DEFAULT_LANG = 'en';

// maximum number of documents that can be uploaded, there is the sumsub limitation.
export const MAX_PHOTO_ID_FILES_NUMBER = 4;

export const MAX_LARGE_IMAGE_FILE_SIZE = 1024 * 1024 * 20;
export const MAX_LARGE_TEXT_FILE_SIZE = 1024 * 1024;
export const MAX_FILES_SIZE_IN_DROPZONE = 45000000;

export const DEFAULT_AMOUNT = 10000;
export const MAX_AMOUNT = 10000;
export const MIN_AMOUNT = 1;

export const ACCOUNT_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  INITIAL: 'initial',
};

export const MT4_PLATFORM = 'mt4';
export const MT5_PLATFORM = 'mt5';

export const TRADING_PLATFORMS = [
  { value: MT4_PLATFORM, label: 'MT4' },
  { value: MT5_PLATFORM, label: 'MT5' },
];

export const DEFAULT_TRADING_PLATFORM = MT4_PLATFORM;
export const DEFAULT_ACCOUNT_TYPE_VALUE = ACCOUNT_TYPES[0].value;
export const GP_COPY_PLATFORMS = [MT4_PLATFORM];

export const LEVERAGES = {
  RETAIL: [{ value: '1', label: '1:1' }],
  PRO: [
    { value: '500', label: '1:500' },
    { value: '400', label: '1:400' },
    { value: '300', label: '1:300' },
    { value: '200', label: '1:200' },
    { value: '100', label: '1:100' },
    { value: '75', label: '1:75' },
    { value: '50', label: '1:50' },
    { value: '25', label: '1:25' },
    { value: '10', label: '1:10' },
    { value: '5', label: '1:5' },
    { value: '1', label: '1:1' },
  ],
};

const CURRENCIES_GP_DEV = [
  { value: 'eur', label: 'EUR' },
  { value: 'usd', label: 'USD' },
  // { value: 'thb', label: 'THB' },
];

export const CURRENCIES_GP = [
  { value: 'aud', label: 'AUD' },
  { value: 'cad', label: 'CAD' },
  { value: 'eur', label: 'EUR' },
  // { value: 'chf', label: 'CHF' },
  { value: 'gbp', label: 'GBP' },
  // { value: 'hkd', label: 'HKD' },
  { value: 'jpy', label: 'JPY' },
  // { value: 'nzd', label: 'NZD' },
  { value: 'sgd', label: 'SGD' },
  { value: 'usd', label: 'USD' },
  // { value: 'thb', label: 'THB' },
];

const CURRENCIES_GPX = [
  { value: 'aud', label: 'AUD' },
  { value: 'cad', label: 'CAD' },
  { value: 'eur', label: 'EUR' },
  // { value: 'chf', label: 'CHF' },
  { value: 'gbp', label: 'GBP' },
  // { value: 'hkd', label: 'HKD' },
  { value: 'jpy', label: 'JPY' },
  // { value: 'nzd', label: 'NZD' },
  { value: 'sgd', label: 'SGD' },
  { value: 'usd', label: 'USD' },
  // { value: 'thb', label: 'THB' },
];

const CURRENCIES_CLIM = [
  { value: 'usd', label: 'USD' },
  { value: 'cad', label: 'CAD' },
  { value: 'eur', label: 'EUR' },
  { value: 'gbp', label: 'GBP' },
  // { value: 'hkd', label: 'HKD' },
  { value: 'jpy', label: 'JPY' },
  // { value: 'nzd', label: 'NZD' },
  { value: 'sgd', label: 'SGD' },
  // { value: 'thb', label: 'THB' },
];

export const CURRENCIE_LIST = {
  development: CURRENCIES_GP_DEV,
  testing: CURRENCIES_GPX,
  staging: CURRENCIES_GP,
  production: CURRENCIES_GP,
  productionX: CURRENCIES_GPX,
  developmentCLIM: CURRENCIES_CLIM,
  productionCLIM: CURRENCIES_CLIM,
};

export const CURRENCIES_BY_HUB = {
  GLOBAL_PRIME: CURRENCIE_LIST['production'],
  GLOBAL_PRIME_X: CURRENCIE_LIST['productionX'],
};

export const ALL_CURRENCIES = [
  { value: 'aud', label: 'AUD', flag: audFlag },
  { value: 'cad', label: 'CAD', flag: cadFlag },
  { value: 'eur', label: 'EUR', flag: eurFlag },
  { value: 'chf', label: 'CHF', flag: chfFlag },
  { value: 'gbp', label: 'GBP', flag: gbpFlag },
  { value: 'hkd', label: 'HKD', flag: hkdFlag },
  { value: 'jpy', label: 'JPY', flag: jpyFlag },
  { value: 'nzd', label: 'NZD', flag: nzdFlag },
  { value: 'sgd', label: 'SGD', flag: sgdFlag },
  { value: 'usd', label: 'USD', flag: usdFlag },
  // { value: 'thb', label: 'THB' },
];

export const CURRENCIES = CURRENCIE_LIST['production'];

export const INPUT_DELAY = 500;

export const AUSTRALIA = 'Australia';

export const CHINA = 'China';

export const CH_COUNTRIES = ['Taiwan', 'China'];

export const SEA_COUNTRIES = ['Thailand', 'Viet Nam', 'Vietnam', 'Malaysia', 'Indonesia'];

export const MANAGED_COUNTRIES = ['China', 'Thailand'];

export const PAYMENT_TYPES = {
  DEPOSIT: 'Deposit',
  WITHDRAWAL: 'Withdrawal',
};

export const THAI_DEFAULT_CXD = '37017_0';
export const THAILAND = 'Thailand';
export const SEYCHELLES = 'Seychelles';

export const EMAIL_TEMPLATE_TYPES = {
  AUTOMATED: 'Automated',
  MANUALLY_SENT: 'Manually Sent',
};

export const ACCOUNT_SERVERS = {
  LIVE: 'live',
  DEMO: 'demo',
  REBATE: 'rebate',
  EXISTING_REBATE: 'existing',
};

export const ACCOUNT_SERVERS_VALUES = [
  { value: ACCOUNT_SERVERS.LIVE, label: 'Live' },
  { value: ACCOUNT_SERVERS.DEMO, label: 'Demo' },
  { value: ACCOUNT_SERVERS.REBATE, label: 'Rebate' },
  { value: ACCOUNT_SERVERS.EXISTING_REBATE, label: 'Rebate (Existing Account)' },
];

export const languages = {
  ENGLISH: 'en',
  THAI: 'th',
  CHINESE: 'ch',
  CANTON: 'chm',
  INDONESIAN: 'id',
  JAPANESE: 'jp',
};

export const MAX_DEMO_LIFE_PERIOD_IN_DAYS = 30;

export const PAYMENT_METHODS = {
  praxis: 'Praxis',
  paymentAsia: 'Local Bank/UPOP',
  isignthis: 'Visa/Mastercard',
  bank: 'Bank Wire/Transfer',
  //scb: 'SCB',
  thaiTransfer: 'Thai Bank Transfer (recommended)',
  crypto: 'Crypto',
};

export const COUNTRY_CODES_FOR_COOKIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'IS',
  'LI',
  'NO',
];

export const FC_FRAME_BOTTOM_CLASS = 'fcFrameBottom';

export const GLOBAL_PRIME_X = 'GLOBAL_PRIME_X';

export const GLOBAL_PRIME = 'GLOBAL_PRIME';

export const GLOBAL_PRIME_LABEL = 'Global Prime AU';

export const GLOBAL_PRIME_X_LABEL = 'Global Prime EN';

export const GLOBAL_PRIME_PREFIX = '/au';

export const GLOBAL_PRIME_X_PREFIX = '/en';

export const MAP_HUB_BY_PREFIX = {
  [GLOBAL_PRIME_PREFIX]: GLOBAL_PRIME,
  [GLOBAL_PRIME_X_PREFIX]: GLOBAL_PRIME_X,
};

export const CURRENCY_MAPPER = {
  Australia: 'AUD',
  Canada: 'CAD',
  'United Kingdom of Great Britain and Northern Ireland': 'GBP',
  Singapore: 'SGD',
  Albania: 'EUR',
  Andorra: 'EUR',
  Armenia: 'EUR',
  Austria: 'EUR',
  Belarus: 'EUR',
  Belgium: 'EUR',
  'Bosnia and Herzegovina': 'EUR',
  Bulgaria: 'EUR',
  Switzerland: 'EUR',
  Croatia: 'EUR',
  Cyprus: 'EUR',
  'Czech Republic': 'EUR',
  Denmark: 'EUR',
  Estonia: 'EUR',
  'Faroe Islands': 'EUR',
  Finland: 'EUR',
  France: 'EUR',
  Georgia: 'EUR',
  Germany: 'EUR',
  Gibraltar: 'EUR',
  Greece: 'EUR',
  Hungary: 'EUR',
  Iceland: 'EUR',
  Ireland: 'EUR',
  Italy: 'EUR',
  Japan: 'JPY',
  Latvia: 'EUR',
  Lithuania: 'EUR',
  Luxembourg: 'EUR',
  'Macedonia (the former Yugoslav Republic of)': 'EUR',
  Malta: 'EUR',
  Monaco: 'EUR',
  Netherlands: 'EUR',
  Norway: 'EUR',
  Portugal: 'EUR',
  Romania: 'EUR',
  'San Marino': 'EUR',
  Slovakia: 'EUR',
  Slovenia: 'EUR',
  Spain: 'EUR',
  Sweden: 'EUR',
};

export const UPLOAD_EMAIL_FILE_ROUTE = `${domain}email-templates/uploadFileBlob`;

export const DEFAULT_CURRENCY = 'USD';

export const DROPDOWN_YES_NO = [
  { value: 'NO', label: 'NO' },
  { value: 'YES', label: 'YES' },
];

export const CLICK_ID_COOKIE_NAME = 'clickid';
export const REFCODE_COOKIE_NAME = 'refcode';
export const MSCLKID_COOKIE_NAME = 'msclkid';

export const isProduction = process.env.REACT_APP_ENV.includes('production');
export const isStagingEnv = process.env.REACT_APP_ENV.includes('staging');
export const isDevelopment = process.env.REACT_APP_ENV === 'development';

export const CURRENCY = {
  AUD: { value: 'aud', label: 'AUD', flag: audFlag },
  CAD: { value: 'cad', label: 'CAD', flag: cadFlag },
  EUR: { value: 'eur', label: 'EUR', flag: eurFlag },
  GBP: { value: 'gbp', label: 'GBP', flag: gbpFlag },
  JPY: { value: 'jpy', label: 'JPY', flag: jpyFlag },
  SGD: { value: 'sgd', label: 'SGD', flag: sgdFlag },
  USD: { value: 'usd', label: 'USD', flag: usdFlag },
  THB: { value: 'thb', label: 'THB', flag: thbFlag },
  NZD: { value: 'nzd', label: 'NZD', flag: nzdFlag },
  HKD: { value: 'hkd', label: 'HKD', flag: hkdFlag },
  KRW: { value: 'KRW', label: 'KRW' },
  // 7 new currencies
  HUF: { value: 'huf', label: 'HUF', flag: hufFlag },
  CZK: { value: 'czk', label: 'CZK', flag: czkFlag },
  DKK: { value: 'dkk', label: 'DKK', flag: dkkFlag },
  PLN: { value: 'pln', label: 'PLN', flag: plnFlag },
  RON: { value: 'ron', label: 'RON' },
  SEK: { value: 'sek', label: 'SEK', flag: sekFlag },
  NOK: { value: 'nok', label: 'NOK', flag: nokFlag },
  CHF: { value: 'chf', label: 'CHF', flag: chfFlag },
  PHP: { value: 'php', label: 'PHP', flag: phpFlag },
  CNY: { value: 'cny', label: 'CNY', flag: cnyFlag },
  MYR: { value: 'myr', label: 'MYR', flag: myrFlag },
  IDR: { value: 'idr', label: 'IDR', flag: idrFlag },
  VND: { value: 'vnd', label: 'VND', flag: vndFlag },
};

export const EU_CURRENCIES_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
];
export const HUBS = {
  GLOBAL_PRIME: 'GLOBAL_PRIME',
  GLOBAL_PRIME_X: 'GLOBAL_PRIME_X',
};
