import { createSelector } from 'reselect';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import { formValueSelector } from 'redux-form';

import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import { getUser } from 'modules/auth/selectors';
import { PAYMENT_METHODS } from 'constants/index';
import { DEPOSIT_CARD_FORM, DEPOSIT_METHOD_FORM, CURRENCIES_PAYMENT_ASIA } from '../constants';

const getDeposit = (state) => cloneDeep(get(state, 'payments.deposit', {}));
const getDepositSelector = createSelector(getDeposit, (deposit) => {
  return deposit;
});

export const getBankWire = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'bankWire', []);
});

export const getCurrencyInfo = (bankInfos, selectedAccount, isThaiTransfer) => {
  const currencyInfo =
    bankInfos.find((bank) =>
      isThaiTransfer ? bank.currency === 'thb' : bank.currency === selectedAccount.currency,
    ) || {};
  currencyInfo.reference = selectedAccount.mt4login;
  return currencyInfo;
};

export const getBankWireAccounts = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'bankWire.liveAccounts', []);
});

export const getHistory = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'history', []);
});

export const getRedirectUrl = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'redirectUrl', '');
});

export const getAmount = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'amount', '');
});
export const getCurrency = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'currency', '');
});
export const getAccountId = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'accountId', '');
});

export const getAccount = createSelector(
  getAccountId,
  accountsSelectors.getLiveAccounts,
  (accountId, liveAccounts) => {
    return find(liveAccounts, { _id: accountId }) || {};
  },
);

export const getFrom = (state) => {
  const selector = formValueSelector(DEPOSIT_CARD_FORM);
  return selector(state, 'accountId') || '';
};

export const getFromAccount = createSelector(
  getFrom,
  accountsSelectors.getAccounts,
  (from, accounts) => {
    if (from && accounts.length) {
      return accounts.find((a) => a._id === from);
    }
    return null;
  },
);

export const getToCurrency = (state) => {
  const selector = formValueSelector(DEPOSIT_CARD_FORM);
  return selector(state, 'currency') || '';
};

export const getFromCurrency = createSelector(getFromAccount, (fromAccount) =>
  get(fromAccount, 'currency', ''),
);

export const getRate = createSelector(getDeposit, (deposit) => {
  return get(deposit, 'rate', '');
});

export const getSelectedMethod = (state) => {
  const selector = formValueSelector(DEPOSIT_METHOD_FORM);
  return selector(state, 'method') || '';
};

export const getDepositInitialValues = createSelector(
  getSelectedMethod,
  accountsSelectors.getDefaultAccount,
  (depositMethod, defaultAccount) => {
    const depositInitalValues = {
      ...defaultAccount,
    };
    if (depositMethod === PAYMENT_METHODS.paymentAsia) {
      depositInitalValues.currency = CURRENCIES_PAYMENT_ASIA[0].value;
    }
    return depositInitalValues;
  },
);

export const getBankWireInitialValues = createSelector(
  getUser,
  accountsSelectors.getDefaultAccount,
  (user, account) => {
    return {
      ...account,
    };
  },
);

export const getQrImage = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'qrImage', '');
});

export const isSCBPayment = createSelector(getSelectedMethod, (paymentMethod) => {
  return paymentMethod === PAYMENT_METHODS.scb;
});

export const getFinmo = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'finmo');
});

export const getMonoova = createSelector(getDepositSelector, (deposit) => {
  return get(deposit, 'monoova');
});

const getCryptoDay1State = (state) => state.payments?.deposit?.cryptoDay1;

export const getCryptoDay1Assets = createSelector(getCryptoDay1State, (state) => state?.assets);
export const getCryptoDay1SpotPrice = createSelector(
  getCryptoDay1State,
  (state) => state?.spotPrice,
);
export const getCryptoDay1WalletSpotPrice = createSelector(
  getCryptoDay1State,
  (state) => state?.walletSpotPrice,
);
export const getCryptoDay1Wallets = createSelector(getCryptoDay1State, (state) => state?.wallets);
export const getCryptoDay1SelectedAccount = createSelector(
  getCryptoDay1State,
  (state) => state?.selectedAccount,
);
export const getCryptoDay1DefaultWalletId = createSelector(
  getCryptoDay1State,
  (state) => state?.defaultWalletId,
);

export const getCryptoDay1Deposit = createSelector(
  [
    getCryptoDay1Assets,
    getCryptoDay1SelectedAccount,
    getCryptoDay1Wallets,
    getCryptoDay1WalletSpotPrice,
    getCryptoDay1SpotPrice,
  ],
  (assets, selectedAccount, wallets, walletSpotPrice, spotPrice) => ({
    assets,
    selectedAccount,
    wallets,
    walletSpotPrice,
    spotPrice,
  }),
);
