import { createAction } from 'redux-actions';

export const getAutomatedApprovalsRequest = createAction('GET_AUTOMATED_APPROVALS_REQUEST');
export const getAutomatedApprovalsSuccess = createAction('GET_AUTOMATED_APPROVALS_SUCCESS');
export const getAutomatedApprovalsFail = createAction('GET_AUTOMATED_APPROVALS_FAIL');

export const generateAutomatedApprovalWithdrawalsRequest = createAction(
  'GENERATE_AUTOMATED_APPROVAL_WITHDRAWALS_REQUEST',
);
export const generateAutomatedApprovalWithdrawalsSuccess = createAction(
  'GENERATE_AUTOMATED_APPROVAL_WITHDRAWALS_SUCCESS',
);
export const generateAutomatedApprovalWithdrawalsFail = createAction(
  'GENERATE_AUTOMATED_APPROVAL_WITHDRAWALS_FAIL',
);
