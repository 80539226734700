const GAP_BETWEEN_ITEMS = 3;

const getColumnFlexValue = (theme, columns, gap) =>
  `0 1 calc(${100 / columns}% - ${theme.spacing(gap)})`;

export default {
  descriptionList: (theme) => ({
    margin: 0,
    flex: 1, // stretch to container
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${theme.spacing(2)} ${theme.spacing(GAP_BETWEEN_ITEMS)}`,
    marginBottom: 0,
  }),
  descriptionListItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  },
  boldText: (theme) => ({
    fontWeight: theme.typography.fontWeightMedium,
  }),
  fullWidth: {
    width: '100%',
  },
  columns1: (theme) => ({
    '& > *': {
      flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
    },
  }),
  columns2: (theme) => ({
    '& > *': {
      flex: getColumnFlexValue(theme, 2, GAP_BETWEEN_ITEMS),
    },
    '& > $fullWidth': {
      flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
    },
  }),
  columns3: (theme) => ({
    '& > *': {
      flex: getColumnFlexValue(theme, 3, GAP_BETWEEN_ITEMS),
    },
    '& > $fullWidth': {
      flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
    },
  }),
  columns4: (theme) => ({
    '& > *': {
      flex: getColumnFlexValue(theme, 4, GAP_BETWEEN_ITEMS),
    },
    '& > $fullWidth': {
      flex: getColumnFlexValue(theme, 1, GAP_BETWEEN_ITEMS),
    },
  }),
  smallSize: (theme) => ({
    gap: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  }),
  wordBreakAll: {
    wordBreak: 'break-all',
  },
  listItem: {
    display: 'inline-grid',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  list: {
    width: '100%',
  },
  summary: (theme) => ({
    backgroundColor: theme.palette.primary.lightest,
    borderRadius: '10px',
    padding: '12px 16px',
  }),
  divider: {
    margin: 0,
  },
};
