import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createTransform, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import omit from 'lodash/omit';

import rootReducer from '../rootReducer';
import rootSaga, { createSagaInjector } from '../rootSaga';

const authTransform = createTransform(
  (state) => state,
  (state) => omit(omit(state, 'isFirstVisit'), 'signIn.showCaptcha'),
  { whitelist: ['auth'] },
);

const rootPersistConfig = {
  key: 'authData',
  storage: storage,
  whitelist: ['auth', 'hideMessage', 'hideCompleteAML'],
  transforms: [authTransform],
};

const sagaMiddleware = createSagaMiddleware();

export function configureStore() {
  let store;

  if (process.env.NODE_ENV === 'production') {
    store = createStore(
      persistReducer(rootPersistConfig, rootReducer),
      applyMiddleware(sagaMiddleware),
    );
  } else {
    const composeEnhancers = composeWithDevTools({
      trace: true,
    });

    store = createStore(
      persistReducer(rootPersistConfig, rootReducer),
      composeEnhancers(applyMiddleware(sagaMiddleware)),
    );
  }

  store.injectSaga = createSagaInjector(sagaMiddleware.run);
  sagaMiddleware.run(rootSaga);

  let persistor = persistStore(store);

  return { store, persistor };
}
