import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18n';
import { getUserLanguage } from 'modules/auth/selectors';
import React, { useEffect, Suspense, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FreeChat from '../FreeChat';
import { useDispatch, useSelector } from 'react-redux';
import Provider from 'react-redux/es/components/Provider';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import MaintenanceRoutes from 'routesMaintenance';
import NotFoundPage from 'modules/common/NotFoundPage';
import { configureStore } from 'store/configureStore';
import ErrorBoundary from '../../modules/errorBoundary';
import CookiesBanner from '../CookiesBanner';
import CustomThemeProvider from '../CustomThemeProvider';
import ScrollToTopWrapper from '../ScrollToTopWrapper';
import styles from './styles';
import { BottomNavigationProvider } from '../../modules/menu/context/bottomNavigationContext';
import useMaintenancePage from '../../hooks/useMaintenancePage';
import CustomThemeProviderMui5 from '../CustomThemeProviderMui5';
import UIComponents from 'modules/UIComponents';

import VerificationModalsManagerContainer from '../../modules/VerificationModalsManager/containers/VerificationModalsManagerContainer';
import config from '../../config';
import PrivateRoute from 'modules/privateRoute/container/PrivateRouteContainer';
import LocationHandler from 'components/LocationHandler';
import ReduxNavigation from '../../modules/reduxNavigation';
import { GLOBAL_PRIME_PREFIX, GLOBAL_PRIME_X_PREFIX } from '../../constants';
import * as actions from '../App/actions';
import { getAvailableRoutes } from '../../modules/menu/selectors';
import { flatMap } from 'lodash';

export const { store, persistor } = configureStore();

const RouteHandler = () => {
  const dispatch = useDispatch();
  const availableRoutes = useSelector(getAvailableRoutes);
  const isMaintenance = useMaintenancePage();
  const [forceRedirect, setForceRedirect] = useState(false);
  const params = useParams();

  const hub =
    params.hub && [GLOBAL_PRIME_PREFIX, GLOBAL_PRIME_X_PREFIX].includes(`/${params.hub}`)
      ? `/${params.hub}`
      : '';
  const history = useHistory();
  const lang = useSelector(getUserLanguage);
  const { pathname } = history.location;
  const baseName = (config.HUB_PREFIX || '') + hub;

  useEffect(() => {
    let isForceRedirect = false;
    const userHub = lang;
    if (userHub && userHub !== hub) {
      const newPathName = hub ? pathname.replace(`${hub}/`, `${userHub}/`) : userHub + pathname;
      if (pathname !== newPathName) {
        isForceRedirect = true;
      }
    }
    setForceRedirect(isForceRedirect);
  }, [lang, hub, pathname]);

  useEffect(() => {
    if (forceRedirect) {
      const userHub = lang;
      const { pathname, search } = history.location;
      history.replace(`${pathname.replace(`${hub}/`, `${userHub}/`)}${search ?? ''}`);
    }
  }, [forceRedirect, history, hub, lang]);

  useEffect(() => {
    dispatch(actions.updateHub(hub));
  }, [dispatch, hub]);

  useEffect(() => {
    dispatch(actions.updateBasename(baseName));
  }, [dispatch, baseName]);

  return !forceRedirect ? (
    <BrowserRouter key={hub} basename={baseName}>
      <ReduxNavigation />
      <LocationHandler />
      <Switch>
        {isMaintenance ? MaintenanceRoutes() : null}
        {flatMap(availableRoutes, ({ isPrivate, path, ...props }, index) => {
          if (Array.isArray(path)) {
            return path.map((individualPath, pathIndex) =>
              isPrivate ? (
                <PrivateRoute
                  key={`${individualPath}-${pathIndex}-${index}`}
                  path={individualPath}
                  {...props}
                />
              ) : (
                <Route
                  key={`${individualPath}-${pathIndex}-${index}`}
                  path={individualPath}
                  {...props}
                />
              ),
            );
          }

          return isPrivate ? (
            <PrivateRoute key={`${path}-${index}`} path={path} {...props} />
          ) : (
            <Route key={`${path}-${index}`} path={path} {...props} />
          );
        })}
        {/* UI Components */}
        {process.env.REACT_APP_ENV === 'development' && (
          <Route path={'/ui-components'} component={UIComponents} />
        )}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  ) : null;
};

const Router = () => {
  return (
    <Suspense fallback={''}>
      <Switch>
        <Route path="/:hub?" component={RouteHandler} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId={config.GOOGLE_OAUTH.CLIENT_ID}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter basename={config.HUB_PREFIX}>
              <CustomThemeProviderMui5>
                <CustomThemeProvider>
                  <CssBaseline />
                  <ScrollToTopWrapper>
                    <ErrorBoundary>
                      <BottomNavigationProvider>
                        <Router />
                        <FreeChat license="7eb6d36b-9a3a-4468-9400-751f2cc30a3f" />
                      </BottomNavigationProvider>
                      <VerificationModalsManagerContainer />
                    </ErrorBoundary>
                  </ScrollToTopWrapper>
                  <CookiesBanner />
                </CustomThemeProvider>
              </CustomThemeProviderMui5>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  );
};

export default withStyles(styles)(App);
