import api from 'modules/api/index';
import get from 'lodash/get';
import omit from 'lodash/omit';
import getFormData from './getFormData';

export const loadUsersRequest = (payload) => {
  return api.get('/users', payload);
};

export const getUpdatedUserRequest = (id) => {
  return api.get(`/users/${id}`);
};

export const getUserCxdRequest = (id) => {
  return api.get(`/users/${id}/cxd`);
};

export const getVerificationUserRequest = (id) => {
  return api.get(`/users/${id}/fullData`);
};

export const getApplicationLogRequest = (id) => {
  return api.get(`/users/${id}/application-log`);
};

export const addEmployeeRequest = (payload) => {
  return api.post('/users/addEmployee', payload);
};

export const addClientRequest = (payload) => {
  delete payload.isIBClient;

  const formData = getFormData(payload);
  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  return api.post('/users/addClient', formData, headers);
};

export const getUserRequest = (id) => {
  return api.get(`/users/${id}/fullData`);
};

export const getUserPhoneNumberRequest = (id) => api.get(`/users/${id}/phoneNumber`);

export const updateUserDetailsRequest = (payload) => {
  let payloadForBackend = omit(payload, 'formName');
  const { _id } = payloadForBackend;
  const formData = getFormData(payloadForBackend);
  let headers = {
    'Content-Type': 'multipart/form-data',
  };
  return api.patch(`/users/${_id}`, formData, headers);
};

export const updateUserLeiRequest = (payload) => {
  const { _id, ...rest } = payload;
  return api.patch(`/users/${_id}/lei`, rest);
};

export const getUserLeiInfoRequest = (payload) => {
  const { _id } = payload;
  return api.get(`/users/${_id}/lei-info`);
};

export const getUserAccountsRequest = (id) => {
  return api.get(`/users/${id}/accounts`);
};

export const getTradingHistoryRequest = (id) => {
  return api.get(`/users/${id}/trading-history`);
};

export const getUserEmailsRequest = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/users/${userId}/emails`, { params });
};

export const getUserPaymentsRequest = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/users/${userId}/payments`, { params });
};

export const sendEmailRequest = (payload) => {
  return api.post(`/users/contactByEmail`, payload);
};

export const addCommentRequest = (payload) => {
  return api.post(`/comments`, payload);
};

export const changePasswordAdminRequest = (payload) => {
  return api.post(`/auth/change-password-admin`, payload);
};

export const sendMigrationEmailRequest = (payload) => {
  return api.post(`/migration/send-migration-email`, payload);
};

export const userMigrationRequest = (payload) => {
  return api.post(`/migration/migrate`, payload);
};

export const acceptMigrationRequest = (payload) => {
  return api.post('/migration/accept-migration-request', payload);
};

export const createAccountAdminRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/account`, payload);
};

export const changePaymentSettingsRequest = (payload) => {
  const { userId } = payload;
  return api.patch(`/users/${userId}/payments`, payload);
};

export const deleteDemoAccountRequest = (id) => {
  return api.delete(`/account/${id}`);
};

export const createAffiliateModalRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/register-affiliate`, payload);
};

export const getSubscriptionModalRequest = (payload) => {
  const { userId } = payload;
  return api.get(`/users/${userId}/operation-subscribe`, payload);
};

export const getOperationNotificationsRequest = (payload) => {
  const { ...params } = payload;
  return api.get(`/users/operation-notification`, { params });
};
export const getDeletedUsersRequest = (payload) => {
  return api.post(`/users/deleted-users`, payload);
};

export const restoreDeletedUserRequest = ({ userId }) => {
  return api.post(`/users/restore/${userId}`);
};

export const getNewOperationNotificationsRequest = (payload) => {
  return api.get(`/users/operation-notification/count`, payload);
};

export const createSubscriptionModalRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/operation-subscribe`, payload);
};

export const removeSubscriptionModalRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/operation-subscribe/remove`, payload);
};

export const setAllowCopyAccountRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/allow-copy-account`, payload);
};

export const createIBClientRequest = (payload) => {
  const { userId } = payload;
  return api.put(`/users/ib-client/${userId}`);
};

export const addIBClientRequest = (payload) => {
  const phoneCountry = get(payload, 'phoneCountry.name', '');
  return api.post(`/users/ib-client/`, { ...payload, phoneCountry });
};

export const getEmployeesRequest = (payload) => {
  return api.get(`/users/employees`, { params: payload });
};

export const editEmployeesPermissionsRequest = ({ user, data }) => {
  return api.patch(`/users/employees/${user._id}/permissions`, data);
};

export const editEmployeesAccessRequest = ({ user, data }) => {
  return api.patch(`/users/employees/${user._id}/access`, data);
};

export const editEmployeesPasswordRequest = ({ user, password }) => {
  return api.patch(`/users/employees/${user._id}/password`, { password });
};

export const editEmployeesAssignedCxdRequest = ({ user, assignedCxds }) => {
  return api.patch(`/users/employees/${user._id}/assign-cxd`, { assignedCxds });
};

export const editEmployeesDisableMfaRequest = ({ user }) => {
  return api.patch(`/users/employees/${user._id}/disable-mfa`);
};

export const getEmailSendersRequest = (payload) => {
  return api.get(`/users/email-senders`, { params: payload });
};

export const getBlockedUsersRequest = (payload) => {
  return api.get(`/users/lock`, { params: payload });
};

export const getBirthdaysRequest = (payload) => {
  return api.get(`/users/birthdays`, { params: payload });
};

export const lockUserRequest = ({ userId }) => {
  return api.post(`/users/${userId}/lock`);
};

export const unlockUserRequest = ({ userId }) => {
  return api.delete(`/users/${userId}/lock`);
};

export const getAffiliateDataRequest = ({ userId, email }) => {
  const query = email ? `?email=${email}` : '';
  return api.get(`/users/${userId}/affiliate${query}`);
};

export const createCellxpertTransferRequest = ({ account, data }) => {
  const { userId, _id } = account;
  const { amount, email } = data;
  return api.post(`/users/${userId}/affiliate/transfer/${_id}`, {
    amount: parseFloat(amount),
    email,
  });
};

export const getSimilarUsersRequest = (id) => {
  return api.get(`/users/${id}/similar`);
};

export const getUserCycleRequest = (id) => {
  return api.get(`/users/${id}/user-cycle`);
};

export const getUserEmailFeedRequest = ({ userId }) => {
  return api.get(`/users/${userId}/email-feed`);
};

export const getCountryUsersRequest = (payload) => {
  return api.get(`/users/countries`, { params: payload });
};

export const getCountryUsersCompareRequest = (payload) => {
  return api.get(`/users/countries-compare`, { params: payload });
};

export const getCountryUsersCompareExportRequest = (payload) => {
  return api.get(`/users/countries-compare`, { params: payload });
};

export const getMarketingChannelDataRequest = (payload) => {
  return api.get(`/users/marketing`, { params: payload });
};

export const getMarketingChannelSourcesRequest = () => {
  return api.get(`/users/marketing/source`);
};

export const getMarketingExportRequest = (payload) => {
  return api.get(`/users/marketing/export`, { params: payload });
};

export const getNetDepositDataRequest = (payload) => {
  return api.get(`/users/net-deposit`, { params: payload });
};

export const setWholesaleUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/wholesale`, payload);
};

export const cellxpertPasswordResetRequest = (token) => {
  return api.post(`/users/ib-password-reset`, { token });
};

export const restrictUserLeverageRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/restrict-leverage`, payload);
};

export const createMailchimpUserRequest = ({ userId }) => {
  return api.post(`/users/${userId}/mailchimp`);
};

export const addLogACallCommentRequest = (payload) => {
  return api.post(`/users/callComments`, payload);
};

export const getLogACallCommentRequest = (payload) => {
  return api.get(`/users/callComments`, { params: payload });
};

export const removeUserRequest = (payload) => {
  return api.delete(`/users/${payload.id}`, { params: payload });
};

export const accountCloseRequest = (payload) => {
  return api.post(`/users/${payload.id}/account/close`, payload);
};

export const accountRestoreRequest = (payload) => {
  return api.post(`/users/${payload.id}/account/restore`, payload);
};

export const setRecentTrsVisibilityRequest = (payload) => {
  return api.post(`/users/set-recent-trs-visibility`, payload);
};

export const getLeadOwnersRequest = () => {
  return api.get('/users/lead-owners');
};

export const setTestUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`/users/${userId}/test`, payload);
};

export const setEcpUserRequest = (payload) => {
  const { userId } = payload;
  return api.post(`users/${userId}/ecp`, payload);
};

export const editTradingStatusRequest = ({ userId, ...payload }) => {
  return api.patch(`/users/${userId}/trading-status`, { params: payload });
};

export const getAccountsWithOpenedTradesRequest = (userId) => {
  return api.get(`users/${userId}/accounts-with-opened-trades`);
};

export const getFusionPlusUsersRequest = (payload) => {
  return api.get(`/users/fusion-plus/users`, { params: payload });
};

export const deleteFusionPlusUsersRequest = (data) => {
  const { _id } = data;
  return api.delete(`/users/fusion-plus/users/${_id}`);
};

export const chargeFusionPlusUsersRequest = (data) => {
  const { _id } = data;
  return api.post(`/users/fusion-plus/users/${_id}/charge`);
};

export const downloadFusionPlusUsersRequest = () => {
  return api.get(`/users/fusion-plus/download`);
};

export const updateKycStatusRequest = (payload) => {
  const { userId, status } = payload;
  return api.put(`/users/${userId}/kyc-status`, { status });
};

export const getPaypalPaymentsManagerRequest = (id) => {
  return api.get(`/users/${id}/paypal-payments`);
};

export const setPaypalPaymentsManagerRequest = (payload) => {
  return api.post(`/users/${payload.userId}/paypal-payments`, {
    payerEmail: payload.payerEmail,
    currency: payload.currency,
    isNonRefundable: payload.isNonRefundable,
  });
};

export const closeGpCopyBannerRequest = (userId) => {
  return api.patch(`/users/${userId}/close-gp-copy-banner`);
};

export const getUserPaypalRefundablePaymentsRequest = (payload) => {
  const { userId, ...params } = payload;
  return api.get(`/users/${userId}/paypal-refundable-payments`, { params });
};

export const getUserPaypalEmailsRequest = (userId) => {
  return api.get(`/users/${userId}/paypal-emails`);
};

export const getUnablePaypalRefundsRequest = ({ userId }) => {
  return api.get(`/users/${userId}/unable-paypal-refund-requests`);
};
