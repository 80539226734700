import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';

import TPSelect from 'components/TP-UI/TPSelect';
import { required } from 'utils/validationRules';

import AccountBalanceWithCurrency from '../AccountBalanceWithCurrency';
import AccountWithCurrency from '../AccountWithCurrency';
import { isMobileOnly } from 'react-device-detect';

const AccountSelect = (props) => {
  const { accounts = [], selectedAccount } = props;
  const { t } = useTranslation('payments');

  const renderOption = useCallback((option) => <AccountWithCurrency {...option} />, []);
  const renderValue = useCallback(
    (option) => <AccountWithCurrency {...option} hideBalance hideFlag={isMobileOnly} />,
    [],
  );

  return (
    <Field
      component={TPSelect}
      fullWidth
      hint={
        selectedAccount ? (
          <AccountBalanceWithCurrency
            currency={selectedAccount.currency}
            value={selectedAccount.balance}
          />
        ) : null
      }
      label={t('common:labels.account')}
      name="accountId"
      options={accounts}
      placeholder={t('common:placeholders.chooseAccount')}
      renderOption={renderOption}
      renderValue={renderValue}
      validate={[required]}
      required
    />
  );
};

AccountSelect.propTypes = {
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountNickname: PropTypes.string,
      currency: PropTypes.string,
      mt4login: PropTypes.string,
    }),
  ),
  loading: PropTypes.bool,
  selectedAccount: PropTypes.shape({
    balance: PropTypes.string,
    currency: PropTypes.string,
  }),
  validate: PropTypes.arrayOf(PropTypes.func),
};

export default AccountSelect;
