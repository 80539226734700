import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { Box } from 'material-latest';
import { useSelector } from 'react-redux';

import { isEuropeanCountry } from 'modules/auth/selectors';
import styles from './styles';
import BankTransferCredentialsNab from './BankTransferCredentialsNab';
import BankTransferCredentialsFps from './BankTransferCredentialsFps';
import BankTransferCredentialsSepa from './BankTransferCredentialsSepa';

const chooseBankTransferCredentialsComponent = (bankTransferDataType) => {
  switch (bankTransferDataType) {
    case 'NAB':
      return BankTransferCredentialsNab;
    case 'FPS':
      return BankTransferCredentialsFps;
    case 'SEPA':
      return BankTransferCredentialsSepa;
  }
};

// We assume there could be only 2 bank transfer credentials
// 0: global (SWIFT)
// 1: local (FPS/SEPA for users from Europe)
const BANK_TRANSFER_CREDENTIALS_INDEX = {
  global: 0,
  local: 1,
};

const BankTransferPaymentCredentials = ({
  classes,
  currency,
  bankTransferDataArr = [],
  onCopy,
}) => {
  const isEuropean = useSelector(isEuropeanCountry);
  const { t } = useTranslation('payments');

  const handleCopy = useCallback(
    (value) => () => {
      onCopy(value);
    },
    [onCopy],
  );

  return (
    <div className={classes.root}>
      <Typography component="h2" className={classes.title}>
        {t('deposit.bankTransfer.title', {
          currency,
        })}
      </Typography>
      {bankTransferDataArr.map((bankTransferData, index) => {
        if (index === BANK_TRANSFER_CREDENTIALS_INDEX.local && !isEuropean) {
          return null;
        }
        let BankTransferCredentialsComponent = chooseBankTransferCredentialsComponent(
          bankTransferData.type,
        );

        return (
          <Box className={classes.section} key={index}>
            <Typography component="h3" className={classes.title}>
              {index === BANK_TRANSFER_CREDENTIALS_INDEX.global
                ? t('deposit.bankTransfer.subtitle')
                : t('deposit.bankTransfer.subtitleLocal', { currency: bankTransferData.currency })}
            </Typography>
            <Typography className={classes.description}>
              {index === BANK_TRANSFER_CREDENTIALS_INDEX.global &&
                t('deposit.bankTransfer.description')}
            </Typography>
            <BankTransferCredentialsComponent
              bankTransferData={bankTransferData}
              t={t}
              handleCopy={handleCopy}
              classes={classes}
            />
          </Box>
        );
      })}
    </div>
  );
};

export default withStyles(styles)(BankTransferPaymentCredentials);
