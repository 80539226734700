import React from 'react';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import includes from 'lodash/includes';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import styles from './styles';
import { VerificationStatuses as VerificationStatus } from 'constants/verificationStatuses';
import { Status as PaymentStatus } from 'constants/paymentStatus';
import approve from 'static/approve.svg';
import pending from 'static/pending.svg';
import decline from 'static/decline.svg';
import {
  UNABLE_PAYPAL_REFUND_REQUEST_STATUS,
  USER_DETAILS_BLOCKED_STATUS,
} from 'modules/userManagement/pages/UserDetails/constants';
import { QUIZ_ANSWER_STATUSES } from '../../../constants/quizAnswerStatuses';
import classnames from 'classnames';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const statusMapper = {
  posted: 'posted',
  approved: 'approved',
  rejected: 'rejected',
  funded: 'funded',
  active: 'active',
  dormant: 'dormant',
  sumsub: 'pending',

  [USER_DETAILS_BLOCKED_STATUS]: USER_DETAILS_BLOCKED_STATUS,
};

const Status = ({ classes, status, className, size = 'small' }) => {
  return (
    <div className={classnames(classes.statusContainer, className)}>
      {(status === VerificationStatus.APPROVED ||
        status === UNABLE_PAYPAL_REFUND_REQUEST_STATUS.APPROVED ||
        status === PaymentStatus.APPROVED ||
        status === VerificationStatus.FUNDED ||
        status === VerificationStatus.ACTIVE ||
        status === QUIZ_ANSWER_STATUSES.CORRECT) && (
        <img className={classes.statusImage} src={approve} alt={status} />
      )}
      {(status === VerificationStatus.NEW ||
        includes(VerificationStatus.EDITING, status) ||
        status === UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PENDING ||
        status === VerificationStatus.POSTED ||
        status === VerificationStatus.AUTOMATIC_APPROVEMENT ||
        status === PaymentStatus.PENDING ||
        status === PaymentStatus.TOCONFIRM) && (
        <img className={classes.statusImage} src={pending} alt={status} />
      )}
      {(status === VerificationStatus.REJECTED ||
        status === PaymentStatus.DECLINED ||
        status === PaymentStatus.EXPIRED ||
        status === UNABLE_PAYPAL_REFUND_REQUEST_STATUS.DECLINED ||
        status === VerificationStatus.DORMANT ||
        status === USER_DETAILS_BLOCKED_STATUS ||
        status === QUIZ_ANSWER_STATUSES.INCORRECT) && (
        <img className={classes.statusImage} src={decline} alt={status} />
      )}
      {status === UNABLE_PAYPAL_REFUND_REQUEST_STATUS.PARTIAL_APPROVED && (
        <CheckCircleOutlineIcon className={classes.partialApprovedIcon} fontSize={size} />
      )}
      <Typography className={classes.dataText}>
        {capitalize(statusMapper[status] || status)}
      </Typography>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string,
};

export default withStyles(styles)(Status);
