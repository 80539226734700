import { useRouteMatch } from 'react-router-dom';
import mergeUrlPaths from '../../../../utils/mergeUrlPaths';
import useMatchUrl from '../../../../hooks/navigation/useMatchUrl';
import { useEffect, useState } from 'react';

const useSelectedTab = (tabs, nav, defaultVal = 0) => {
  const currentBaseUrl = useMatchUrl();
  const match = useRouteMatch(tabs.map((tab) => mergeUrlPaths([currentBaseUrl, tab.path], true)));
  const index = nav
    ? tabs.findIndex(
        (tab) => match?.path && match.path === mergeUrlPaths([currentBaseUrl, tab.path], true),
      )
    : -1;

  useEffect(() => {
    if (nav && match) {
      // temporary fix for redirect from withdraw to payments deposit tab (it redirects few times cause router updates long time and sets first time to wrong tab and break tab selector)
      setTimeout(() => {
        setSelectedTab(index > -1 ? index : false);
      });
    }
  }, [match, currentBaseUrl, tabs, nav, index]);
  /**
   * If there is no matches, then no tab is selected (see MUI Tabs API).
   */
  const [selectedTab, setSelectedTab] = useState(index > -1 ? index : !nav ? defaultVal : false);
  return [selectedTab, setSelectedTab];
};

export default useSelectedTab;
