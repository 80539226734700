import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal } from 'material-latest';

import TPTypography from 'components/TP-UI/TPTypography';
import TPButton from 'components/TP-UI/TPButton';
import TPIconButton from 'components/TP-UI/TPIconButton';
import { SIZES } from 'components/TP-UI/constants';
import TPWizardBackdrop from './components/TPWizardBackdrop';

import { isOpen } from '../../../modules/common/CustomModal/selectors';
import { closeModal } from '../../../modules/common/CustomModal/actions';

import styles from './styles';

export const TPModal = ({
  value,
  title,
  text,
  loading = false,
  onClose,
  children,
  className,
  buttons,
  form,
  onSubmit, //when modal is form modal
  hideClose = false,
  hideButtons = false,
  disableEnforceFocus = false,
  disableScrollLock = false,
  hideBackdrop = false,
  titleEndAdornment,
  disableEscapeKeyDown = false,
  fullWidth = false,
  variant = 'primary',
  justifyContent,
}) => {
  const { t } = useTranslation('common');
  const handleClose = useCallback(
    (event, reason) => {
      // prevent clicking outside
      if (onClose && reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose],
  );

  const displayedButtons = useMemo(() => {
    if (hideButtons) return null;
    return buttons
      ? [...buttons].reverse()
      : [{ label: t('buttons.gotIt'), primary: true, onClick: handleClose }];
  }, [hideButtons, buttons, t, handleClose]);

  const contentTag = form ? 'form' : 'div';
  const contentProps = {};
  if (onSubmit) {
    contentProps.onSubmit = onSubmit;
    contentProps.noValidate = 'novalidate';
  }

  let justify = justifyContent ?? (variant === 'wizard' && !justifyContent ? 'top' : 'center');

  return (
    <Modal
      open={value}
      onClose={handleClose}
      aria-labelledby={title && 'modal-title'}
      aria-describedby={text && 'modal-description'}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableScrollLock={disableScrollLock}
      hideBackdrop={hideBackdrop}
      BackdropComponent={variant === 'wizard' ? TPWizardBackdrop : undefined}
      sx={[variant === 'wizard' && styles.rootWizard]}>
      <Box
        component={contentTag}
        sx={[
          variant === 'wizard' && styles.rootWizard,
          justify === 'top' && styles.justifyTop,
          styles.root,
          className,
          fullWidth && styles.fullWidth,
        ]}
        {...contentProps}>
        {!hideClose && (
          <TPIconButton
            aria-label="Close"
            className={styles.closeButton}
            onClick={handleClose}
            size={SIZES.MEDIUM}>
            <CloseIcon />
          </TPIconButton>
        )}
        {title && (
          <Box sx={styles.titleContainer}>
            <TPTypography variant="h3" id="modal-title">
              {title}
              {titleEndAdornment && <Box sx={styles.titleEndAdornment}>{titleEndAdornment}</Box>}
            </TPTypography>
          </Box>
        )}
        <Box sx={styles.contentContainer}>
          <Box sx={styles.content}>
            {text ? (
              <TPTypography variant="body1" id="modal-description">
                {text}
              </TPTypography>
            ) : (
              children
            )}
          </Box>
        </Box>
        {displayedButtons ? (
          <Box sx={styles.buttonContainer}>
            {displayedButtons.map(({ label, ...props }) => (
              <TPButton
                size={SIZES.LARGE}
                {...props}
                key={label}
                loading={props.primary && loading}
                disabled={(props.secondary && loading) || (!loading && props.disabled)}>
                {label}
              </TPButton>
            ))}
          </Box>
        ) : null}
      </Box>
    </Modal>
  );
};

TPModal.propTypes = {
  /**
   * Open/Close modal manually
   */
  value: PropTypes.bool.isRequired,
  /**
   * Modal title
   */
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  /**
   * Modal title end adornment
   */
  titleEndAdornment: PropTypes.node,
  /**
   * Modal text, can be used in confirmation modals
   */
  text: PropTypes.node,
  /**
   * Hide close button and prevent close modal via clicking outside
   */
  hideClose: PropTypes.bool,
  /**
   * Hide modal button's container. Can be used in cases where modal layout is specific
   */
  hideButtons: PropTypes.bool,
  /**
   * Variants of how modal can look like.
   * Primary is default
   * primary - container with gray background backdrop, Content container is centered (horizontally and vertically) with shadow
   * wizard - container with white background, The content container is centered at the top without a shadow. It's wider than primary version.
   */
  variant: PropTypes.oneOf(['primary', 'wizard']),
  /**
   * Align content vertically based on the provided value.
   * Center is default.
   */
  justifyContent: PropTypes.oneOf(['top', 'center']),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      primary: PropTypes.bool,
      secondary: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * If true, the modal will not prevent focus from leaving the modal while open.
   * Generally this should never be set to true as it makes the modal less
   * accessible to assistive technologies, like screen readers.
   * USE WITH CAUTION
   */
  disableEnforceFocus: PropTypes.bool,
  /**
   * If true, the modal will not be closed on ESC key
   * This is not the best approach so use only when
   * there is a strong reason
   */
  disableEscapeKeyDown: PropTypes.bool,
  /**
   * Stretch modal width to browser window
   */
  fullWidth: PropTypes.bool,
};

const TPReduxModal = ({ modalName, onClose, ...props }) => {
  const opened = useSelector((state) => isOpen(state, modalName));
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      dispatch(closeModal(modalName));
    }
  }, [onClose, modalName, dispatch]);

  return <TPModal value={opened} {...props} onClose={handleClose} />;
};

TPReduxModal.propTypes = {
  /**
   * Store open/close modal state in redux store via modalName prop value
   */
  modalName: PropTypes.string.isRequired,
  form: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default TPReduxModal;
