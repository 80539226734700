import { createAction } from 'redux-actions';

export const getMyFollowersRequest = createAction('GET_MY_FOLLOWERS_REQUEST');
export const getMyFollowersSuccess = createAction('GET_MY_FOLLOWERS_SUCCESS');
export const getMyFollowersFail = createAction('GET_MY_FOLLOWERS_FAIL');

export const getMyFollowersAccountsRequest = createAction('GET_MY_FOLLOWERS_ACCOUNTS_REQUEST');
export const getMyFollowersAccountsSuccess = createAction('GET_MY_FOLLOWERS_ACCOUNTS_SUCCESS');
export const getMyFollowersAccountsFail = createAction('GET_MY_FOLLOWERS_ACCOUNTS_FAIL');

export const getMasterAccountsRequest = createAction('GET_MASTER_ACCOUNTS_REQUEST');
export const getMasterAccountsSuccess = createAction('GET_MASTER_ACCOUNTS_SUCCESS');
export const getMasterAccountsFail = createAction('GET_MASTER_ACCOUNTS_FAIL');

export const deleteMasterAccountRequest = createAction('DELETE_MASTER_ACCOUNT_REQUEST');
export const deleteMasterAccountSuccess = createAction('DELETE_MASTER_ACCOUNT_SUCCESS');
export const deleteMasterAccountFail = createAction('DELETE_MASTER_ACCOUNT_FAIL');

export const getMyFollowersMyInvitationsRequest = createAction(
  'GET_MY_FOLLOWERS_MY_INVITATIONS_REQUEST',
);
export const getMyFollowersMyInvitationsSuccess = createAction(
  'GET_MY_FOLLOWERS_MY_INVITATIONS_SUCCESS',
);
export const getMyFollowersMyInvitationsFail = createAction('GET_MY_FOLLOWERS_MY_INVITATIONS_FAIL');

export const getUserFollowersMyInvitationsRequest = createAction(
  'GET_USER_FOLLOWERS_MY_INVITATIONS_REQUEST',
);
export const getUserFollowersMyInvitationsSuccess = createAction(
  'GET_USER_FOLLOWERS_MY_INVITATIONS_SUCCESS',
);
export const getUserFollowersMyInvitationsFail = createAction(
  'GET_USER_FOLLOWERS_MY_INVITATIONS_FAIL',
);

export const getUserFollowersMyFollowersRequest = createAction(
  'GET_USER_FOLLOWERS_MY_FOLLOWERS_REQUEST',
);
export const getUserFollowersMyFollowersSuccess = createAction(
  'GET_USER_FOLLOWERS_MY_FOLLOWERS_SUCCESS',
);
export const getUserFollowersMyFollowersFail = createAction('GET_USER_FOLLOWERS_MY_FOLLOWERS_FAIL');

export const createInvitationLink = createAction('CREATE_INVITATION_LINK');

export const createFollowerInvitationLinkRequest = createAction(
  'CREATE_FOLLOWER_INVITATION_LINK_REQUEST',
);
export const createFollowerInvitationLinkSuccess = createAction(
  'CREATE_FOLLOWER_INVITATION_LINK_SUCCESS',
);
export const createFollowerInvitationLinkFail = createAction(
  'CREATE_FOLLOWER_INVITATION_LINK_FAIL',
);

export const setInviteFollowerId = createAction('SET_INVITE_FOLLOWER_ID');

export const getMyFollowersJoinIdRequest = createAction('GET_MY_FOLLOWERS_JOIN_ID_REQUEST');
export const getMyFollowersJoinIdSuccess = createAction('GET_MY_FOLLOWERS_JOIN_ID_SUCCESS');
export const getMyFollowersJoinIdFail = createAction('GET_MY_FOLLOWERS_JOIN_ID_FAIL');

export const joinAddCopierByInviteRequest = createAction('JOIN_ADD_COPIER_BY_INVITE_REQUEST');
export const joinAddCopierByInviteSuccess = createAction('JOIN_ADD_COPIER_BY_INVITE_SUCCESS');
export const joinAddCopierByInviteFail = createAction('JOIN_ADD_COPIER_BY_INVITE_FAIL');

export const addCopierByEmail = createAction('ADD_COPIER_BY_EMAIL');

export const addCopierByEmailRequest = createAction('ADD_COPIER_BY_EMAIL_REQUEST');
export const addCopierByEmailSuccess = createAction('ADD_COPIER_BY_EMAIL_SUCCESS');
export const addCopierByEmailFail = createAction('ADD_COPIER_BY_EMAIL_FAIL');

export const createOwnCopierRequest = createAction('CREATE_OWN_COPIER_REQUEST');
export const createOwnCopierSuccess = createAction('CREATE_OWN_COPIER_SUCCESS');
export const createOwnCopierFail = createAction('CREATE_OWN_COPIER_FAIL');

export const deleteCopierRequest = createAction('DELETE_COPIER_REQUEST');
export const deleteCopierSuccess = createAction('DELETE_COPIER_SUCCESS');
export const deleteCopierFail = createAction('DELETE_COPIER_FAIL');

export const editCopierRequest = createAction('EDIT_COPIER_REQUEST');
export const editCopierSuccess = createAction('EDIT_COPIER_SUCCESS');
export const editCopierFail = createAction('EDIT_COPIER_FAIL');

export const editSelectedCopiersRequest = createAction('EDIT_SELECTED_COPIERS_REQUEST');
export const editSelectedCopiersSuccess = createAction('EDIT_SELECTED_COPIERS_SUCCESS');
export const editSelectedCopiersFail = createAction('EDIT_SELECTED_COPIERS_FAIL');

export const getFollowerInvitesRequest = createAction('GET_FOLLOWER_INVITES_REQUEST');
export const getFollowerInvitesSuccess = createAction('GET_FOLLOWER_INVITES_SUCCESS');
export const getFollowerInvitesFail = createAction('GET_FOLLOWER_INVITES_FAIL');

export const openAddMyFollower = createAction('OPEN_ADD_MY_FOLLOWER');

export const onCopyToClipboard = createAction('ON_COPY_TO_CLIPBOARD');

export const openDeleteMasterAccountConfirmation = createAction(
  'OPEN_DELETE_MASTER_ACCOUNT_CONFIRMATION',
);
export const closeDeleteMasterAccountConfirmation = createAction(
  'CLOSE_DELETE_MASTER_ACCOUNT_CONFIRMATION',
);

export const openEditConfirmation = createAction('OPEN_EDIT_CONFIRMATION');
export const openSomeFollowersEditConfirmation = createAction('OPEN_EMPTY_EDIT_CONFIRMATION');

export const openReloadAccount = createAction('OPEN_RELOAD_ACCOUNT');

export const sendReloadTsAccountRequest = createAction('SEND_RELOAD_TS_ACCOUNT_REQUEST');
export const sendReloadTsAccountSuccess = createAction('SEND_RELOAD_TS_ACCOUNT_SUCCESS');
export const sendReloadTsAccountFail = createAction('SEND_RELOAD_TS_ACCOUNT_FAIL');

export const deleteInviteRequest = createAction('DELETE_INVITE_REQUEST');
export const deleteInviteSuccess = createAction('DELETE_INVITE_SUCCESS');
export const deleteInviteFail = createAction('DELETE_INVITE_FAIL');

export const openEditInvite = createAction('OPEN_EDIT_INVITE');

export const editInviteRequest = createAction('EDIT_INVITE_REQUEST');
export const editInviteSuccess = createAction('EDIT_INVITE_SUCCESS');
export const editInviteFail = createAction('EDIT_INVITE_FAIL');

export const joinFusionPlus = createAction('JOIN_FUSION_PLUS');

export const openJoinFusionPlus = createAction('OPEN_JOIN_FUSION_PLUS');
export const joinFusionPlusRequest = createAction('JOIN_FUSION_PLUS_REQUEST');
export const joinFusionPlusSuccess = createAction('JOIN_FUSION_PLUS_SUCCESS');
export const joinFusionPlusFail = createAction('JOIN_FUSION_PLUS_FAIL');

export const myFollowersTradesOpenedRequest = createAction('MY_FOLLOWERS_TRADES_OPENED_REQUEST');
export const myFollowersTradesOpenedSuccess = createAction('MY_FOLLOWERS_TRADES_OPENED_SUCCESS');
export const myFollowersTradesOpenedFail = createAction('MY_FOLLOWERS_TRADES_OPENED_FAIL');

export const myFollowersTradesClosedRequest = createAction('MY_FOLLOWERS_TRADES_CLOSED_REQUEST');
export const myFollowersTradesClosedSuccess = createAction('MY_FOLLOWERS_TRADES_CLOSED_SUCCESS');
export const myFollowersTradesClosedFail = createAction('MY_FOLLOWERS_TRADES_CLOSED_FAIL');

export const myFollowersTradesErrorRequest = createAction('MY_FOLLOWERS_TRADES_ERROR_REQUEST');
export const myFollowersTradesErrorSuccess = createAction('MY_FOLLOWERS_TRADES_ERROR_SUCCESS');
export const myFollowersTradesErrorFail = createAction('MY_FOLLOWERS_TRADES_ERROR_FAIL');

export const userFollowersTradesOpenedRequest = createAction(
  'USER_FOLLOWERS_TRADES_OPENED_REQUEST',
);
export const userFollowersTradesOpenedSuccess = createAction(
  'USER_FOLLOWERS_TRADES_OPENED_SUCCESS',
);
export const userFollowersTradesOpenedFail = createAction('USER_FOLLOWERS_TRADES_OPENED_FAIL');

export const userFollowersTradesClosedRequest = createAction(
  'USER_FOLLOWERS_TRADES_CLOSED_REQUEST',
);
export const userFollowersTradesClosedSuccess = createAction(
  'USER_FOLLOWERS_TRADES_CLOSED_SUCCESS',
);
export const userFollowersTradesClosedFail = createAction('USER_FOLLOWERS_TRADES_CLOSED_FAIL');

export const userFollowersTradesErrorRequest = createAction('USER_FOLLOWERS_TRADES_ERROR_REQUEST');
export const userFollowersTradesErrorSuccess = createAction('USER_FOLLOWERS_TRADES_ERROR_SUCCESS');
export const userFollowersTradesErrorFail = createAction('USER_FOLLOWERS_TRADES_ERROR_FAIL');

export const updateSettings = createAction('MY_FOLLOWERS_UPDATE_SETTINGS');

export const openTurnOffModal = createAction('OPEN_TURN_OFF_MODAL');

export const openEditInviteNotifications = createAction('OPEN_EDIT_INVITE_NOTIFICATIONS');

export const editInviteNotificationsRequest = createAction('EDIT_INVITE_NOTIFICATIONS_REQUEST');
export const editInviteNotificationsSuccess = createAction('EDIT_INVITE_NOTIFICATIONS_SUCCESS');
export const editInviteNotificationsFail = createAction('EDIT_INVITE_NOTIFICATIONS_FAIL');

export const requestFusionPlusOpen = createAction('REQUEST_FUSION_PLUS_OPEN');

export const requestFusionPlusRequest = createAction('REQUEST_FUSION_PLUS_REQUEST');
export const requestFusionPlusSuccess = createAction('REQUEST_FUSION_PLUS_SUCCESS');
export const requestFusionPlusFail = createAction('REQUEST_FUSION_PLUS_FAIL');

export const myFollowersRejectInvitationRequest = createAction(
  'MY_FOLLOWERS_REJECT_INVITATION_REQUEST',
);
export const myFollowersRejectInvitationSuccess = createAction(
  'MY_FOLLOWERS_REJECT_INVITATION_SUCCESS',
);
export const myFollowersRejectInvitationFail = createAction('MY_FOLLOWERS_REJECT_INVITATION_FAIL');

export const myFollowersTurnOffInvitationRequest = createAction(
  'MY_FOLLOWERS_TURN_OFF_INVITATION_REQUEST',
);
export const myFollowersTurnOffInvitationSuccess = createAction(
  'MY_FOLLOWERS_TURN_OFF_INVITATION_SUCCESS',
);
export const myFollowersTurnOffInvitationFail = createAction(
  'MY_FOLLOWERS_TURN_OFF_INVITATION_FAIL',
);

export const copyInvitationLink = createAction('COPY_INVITATION_LINK');

export const myFollowersJoinWaitlistCopyRequest = createAction(
  'MY_FOLLOWERS_JOIN_WAITLIST_COPY_REQUEST',
);
export const myFollowersJoinWaitlistCopySuccess = createAction(
  'MY_FOLLOWERS_JOIN_WAITLIST_COPY_SUCCESS',
);
export const myFollowersJoinWaitlistCopyFail = createAction('MY_FOLLOWERS_JOIN_WAITLIST_COPY_FAIL');

export const myFollowersGetWaitlistRequest = createAction('MY_FOLLOWERS_GET_WAITLIST_REQUEST');
export const myFollowersGetWaitlistSuccess = createAction('MY_FOLLOWERS_GET_WAITLIST_SUCCESS');
export const myFollowersGetWaitlistFail = createAction('MY_FOLLOWERS_GET_WAITLIST_FAIL');

export const myFollowersJoinWaitlistRequest = createAction('MY_FOLLOWERS_JOIN_WAITLIST_REQUEST');
export const myFollowersJoinWaitlistSuccess = createAction('MY_FOLLOWERS_JOIN_WAITLIST_SUCCESS');
export const myFollowersJoinWaitlistFail = createAction('MY_FOLLOWERS_JOIN_WAITLIST_FAIL');

export const openMyFollowersJoinWaitlist = createAction('OPEN_MY_FOLLOWERS_JOIN_WAITLIST');
export const openApplyMasterTradesModal = createAction('OPEN_APPLY_MASTER_TRADES_MODAL');

export const getCopyTradesReferenceRequest = createAction('GET_COPY_TRADES_REFERENCE_REQUEST');
export const getCopyTradesReferenceSuccess = createAction('GET_COPY_TRADES_REFERENCE_SUCCESS');
export const getCopyTradesReferenceFail = createAction('GET_COPY_TRADES_REFERENCE_FAIL');

export const openRejectInvitation = createAction('OPEN_REJECT_INVITATION');
