import { PAYMENT_METHODS } from 'constants/index';

export const DEPOSIT_CARD_FORM = 'DEPOSIT_CARD_FORM';
export const DEPOSIT_BANKWIRE_FORM = 'DEPOSIT_BANKWIRE_FORM';
export const DEPOSIT_SMARTWAY_FORM = 'DEPOSIT_SMARTWAY_FORM';

export const CRYPTO_DAY1_WALLET_GENERATION_FORM = 'CRYPTO_DAY1_WALLET_GENERATION_FORM';
export const CRYPTO_DAY1_WALLET_SELECTION_FORM = 'CRYPTO_DAY1_WALLET_SELECTION_FORM';
export const CRYPTO_DAY1_WALLET_GENERATION_MODAL = 'CRYPTO_DAY1_WALLET_GENERATION_MODAL';

export const DEPOSIT_STATUSES = {
  APPROVED: 'success',
  SUCCESS: 'success',
  DECLINED: 'declined',
  REJECTED: 'declined',
  PENDING: 'pending',
};

export const BANK_WIRE_PAYMENT_METHODS = [PAYMENT_METHODS.bank, PAYMENT_METHODS.thaiTransfer];

export const CURRENCIES_LOCAL_TRANSFER = [
  { value: 'myr', label: 'MYR' },
  { value: 'vnd', label: 'VND' },
  { value: 'idr', label: 'IDR' },
  { value: 'thb', label: 'THB' },
];

export const CURRENCIES_PAYMENT_ASIA = [{ value: 'cny', label: 'CNY' }];

export const PAYMENT_METHODS_BASE_CURRENCIES = {
  [PAYMENT_METHODS.local]: CURRENCIES_LOCAL_TRANSFER,
  [PAYMENT_METHODS.paymentAsia]: CURRENCIES_PAYMENT_ASIA,
};

export const MIN_DEPOSITS = {
  usd: 10,
  gbp: 10,
  eur: 10,
  aud: 10,
  cad: 15,
  hkd: 100,
  jpy: 1120,
  nzd: 15,
  sgd: 15,
  myr: 15,
  vnd: 15,
  idr: 15,
  thb: 20,
};

export const MAX_DEPOSITS = {
  usd: 34000,
  gbp: 27000,
  eur: 30000,
  aud: 50000,
  cad: 46000,
  hkd: 270000,
  jpy: 3795000,
  nzd: 52000,
  sgd: 47000,
  myr: 10500,
  vnd: 11000,
  idr: 12000,
  thb: 14000,
};

export const DEPOSIT_SEARCH_FORM = 'DEPOSIT_SEARCH_FORM';

export const THAI_BANK_NAMES = [
  {
    label: 'Bank for Agriculture and Agricultural Cooperatives',
    value: 'Bank for Agriculture and Agricultural Cooperatives',
  },
  { label: 'Islamic Bank of Thailand', value: 'Islamic Bank of Thailand' },
  { label: 'Government Saving Bank', value: 'Government Saving Bank' },
  { label: 'TISCO', value: 'TISCO' },
  { label: 'United Overseas Bank, Thailand', value: 'United Overseas Bank, Thailand' },
  { label: 'Thanachart Bank', value: 'Thanachart Bank' },
  { label: 'Siam Commercial Bank', value: 'Siam Commercial Bank' },
  { label: 'Thai Military Bank', value: 'Thai Military Bank' },
  { label: 'Citibank', value: 'Citibank' },
  { label: 'KasikornBank', value: 'KasikornBank' },
  { label: 'Bank of Ayudhaya', value: 'Bank of Ayudhaya' },
  { label: 'Krung Thai Bank', value: 'Krung Thai Bank' },
  { label: 'Bangkok Bank', value: 'Bangkok Bank' },
];

export const DEPOSIT_METHOD_FORM = 'DEPOSIT_METHOD_FORM';

export const PAYMENT_PROCESS_STATUSES = {
  REDIRECTING: 'redirecting',
};

export const MT4_IS_NOT_CREATED_PLACEHOLDER = '0';

// type: 'NAB' | 'FPS' | 'SEPA"
// [default (swift), local]
export const BANK_TRANSFER_AU = {
  aud: [
    {
      type: 'NAB',
      currency: 'AUD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '255 George Street, Sydney, NSW, 2000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-057',
      accountNumber: '93-292-0181',
      accountName: 'FMGP GP AUD Retail',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  cad: [
    {
      type: 'NAB',
      currency: 'CAD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'FMGPTCAD02',
      accountName: 'FMGP GP CAD Retail',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  eur: [
    {
      type: 'NAB',
      currency: 'EUR',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'FMGPTEUR02',
      accountName: 'FMGP GP EUR Retail',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  gbp: [
    {
      type: 'NAB',
      currency: 'GBP',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'FMGPTGBP02',
      accountName: 'FMGP GP GBP Retail',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  sgd: [
    {
      type: 'NAB',
      currency: 'SGD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'FMGPTSGD02',
      accountName: 'FMGP GP SGD Retail',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  usd: [
    {
      type: 'NAB',
      currency: 'USD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'FMGPTUSD03',
      accountName: 'FMGP GP USD Retail',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
};

export const BANK_TRANSFER = {
  aud: [
    {
      type: 'NAB',
      currency: 'AUD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '255 George Street, Sydney, NSW, 2000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-057',
      accountNumber: '26-534-4853',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX AUD Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  cad: [
    {
      type: 'NAB',
      currency: 'CAD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLCAD10',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX CAD Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  eur: [
    {
      type: 'NAB',
      currency: 'EUR',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLEUR10',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX EUR Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
    {
      type: 'SEPA',
      currency: 'EUR',
      bankAddress:
        'Equals Money UK, Vintners Place, 68 Upper Thames St, London, EC4V 3BJ, United Kingdom',
      bic: 'SPPVGB2LXXX',
      accountName: 'FMGP Trading group Pty Ltd Client',
      iban: 'GB22SPPV23188454968953',
    },
  ],
  gbp: [
    {
      type: 'NAB',
      currency: 'GBP',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLGBP10',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX GBP Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
    {
      type: 'FPS',
      currency: 'GBP',
      bankAddress:
        'Equals Money UK, Vintners Place, 68 Upper Thames St, London, EC4V 3BJ, United Kingdom',
      accountNumber: '54968953',
      accountName: 'FMGP Trading group Pty Ltd Client',
      sortCode: '23-18-84',
    },
  ],
  hkd: [
    {
      type: 'NAB',
      currency: 'HKD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLHKD08',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX HKD Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  jpy: [
    {
      type: 'NAB',
      currency: 'JPY',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLJPY06',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX JPY Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  nzd: [
    {
      type: 'NAB',
      currency: 'NZD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLNZD08',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX NZD Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  sgd: [
    {
      type: 'NAB',
      currency: 'SGD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLSGD09',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX SGD Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
  usd: [
    {
      type: 'NAB',
      currency: 'USD',
      bankName: 'National Australia Bank',
      bankCountry: 'Australia',
      bankAddress: '500 Bourke Street, Melbourne, Victoria 3000, Australia',
      swift: 'NATAAU3303M',
      bsb: '082-039',
      accountNumber: 'GSAPLUSD21',
      accountName: 'Gleneagle Securities (Aust) Pty Limited FX USD Trust P9',
      accountAddress: 'Level 27, 25 Bligh Street, Sydney NSW 2000, Australia',
    },
  ],
};

export const CRYPTO_AVAILABLE_EMAILS = [
  'kurian.amaljose@gmail.com',
  'will.chan063@gmail.com',
  'illiayanovoi@coherentsolutions.com',
  'zhivkometlarov+103@coherentsolutions.com',
];
