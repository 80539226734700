import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import Cookies from 'js-cookie';

import { Roles } from 'constants/userRoles';
import { VerificationStatuses } from 'constants/verificationStatuses';
import { LOCATION_FIELDS } from 'modules/user/constants';
import { getFormattedCountries } from 'modules/geolocation/selectors';
import { getQuizProgressAccessKey } from '../../VerificationModalsManager/utils/getQuizProgressAccessKey';
import {
  ALL_CURRENCIES,
  AUSTRALIA,
  CURRENCIES_BY_HUB,
  DEFAULT_TRADING_PLATFORM,
  GLOBAL_PRIME,
  GLOBAL_PRIME_X,
  isProduction,
  MANAGED_COUNTRIES,
  SEA_COUNTRIES,
  THAILAND,
  TRADING_PLATFORMS,
} from 'constants/index';
import getDocuments from 'modules/common/documents';
import {
  getCurrencyByCountry as getCurrencyByCountryUtil,
  getLeverageByHub as getLeverageByHubUtil,
} from 'utils/getCurrencyByCountry';
import config from '../../../config';
import { ACCOUNTS_LIMIT, DEMO_ACCOUNTS_LIMIT } from 'modules/accounts/pages/MyAccounts/constants';
import { ACCOUNT_PLANS } from '../../../constants/acctountPlans';
import isStateRestricted from '../../../utils/isStateRestricted';
import { UPLOAD_EVIDENCE_STATUSES } from '../../evidenceUploadPortal/constants';
import { isMigrationAllowed } from '../../migrateMyAccount/helpers/isMigrationAllowed';

const ALLOW_IM = new Set(get(config, 'TWILIO.ALLOW_IM'));
const ALLOW_HYPERWALLET_TO_USERS = get(config, 'HYPERWALLET_ALLOWED_USERS');

export const getIsAuthenticated = (state) => get(state, 'auth.isAuthenticated');
export const getRefreshToken = (state) => state.auth.refreshToken;
export const getUser = (state) => state.auth.user || {};
export const getUserIsPro = (state) =>
  get(state, 'user.isPro', false) || get(state, 'auth.user.isPro', false) || false;
export const getIsImpersonation = (state) => get(state, 'auth.isImpersonation', false);

export const accountPlanSelector = createSelector(getUser, (user) => user.selectedPlan);

export const isProSelected = createSelector(accountPlanSelector, (accountPlan) => {
  return accountPlan === ACCOUNT_PLANS.PRO;
});

export const targetUserQuizSelector = createSelector(getUser, (user) => {
  const { selectedPlan, ...userData } = user;

  const quizProgressAccessKey = getQuizProgressAccessKey(selectedPlan);

  if (!quizProgressAccessKey) return null;

  return userData[quizProgressAccessKey];
});

export const getIsRetailQuizPassed = createSelector(getUser, (user) => {
  return get(user, 'retailQuizProgress.isPassed', false);
});

export const getIsProQuizPassed = createSelector(getUser, (user) => {
  return get(user, 'proQuizProgress.isPassed', false);
});

export const getIsProSigned = createSelector(getUser, (user) => !!user.proSign);

export const getUserEvidenceStatementStatus = createSelector(getUser, (user) =>
  get(user, 'tradingStatementEvidence.status', UPLOAD_EVIDENCE_STATUSES.INITIAL),
);
export const getUserProStatus = createSelector(getUser, (user) => get(user, 'isPro', false));
export const getIsUserWholeSale = createSelector(getUser, (user) => get(user, 'wholesale', false));
export const getIsFirstVisit = (state) => state.auth.isFirstVisit;

export const getUserId = createSelector(getUser, (user) => user._id);

export const getUserPaymentSettings = createSelector(getUser, (user) => user.payments || {});

export const getUserStatus = createSelector(getUser, (user) =>
  get(user, 'verification.status', ''),
);

export const getUserTMDData = createSelector(getUser, (user) =>
  get(user, 'verification.TMDAccepted', null),
);

export const getUserHub = () => Cookies.get('hub');

export const getIsTradingDisabled = createSelector(getUser, (user) =>
  get(user, 'tradingStatus.disabled', false),
);

export const getUserLanguage = createSelector(getUserHub, (hub) => {
  if (hub === 'GLOBAL_PRIME') {
    return '/au';
  }

  if (hub === 'GLOBAL_PRIME_X') {
    return '/en';
  }
});

export const isAuHub = createSelector(getUserHub, (hub) => {
  return hub === 'GLOBAL_PRIME';
});

export const isEnHub = createSelector(getUserHub, (hub) => {
  return hub === 'GLOBAL_PRIME_X';
});

export const getMailchimpUser = (state) => state.profile.profile.profileData;
export const getMailchimpUserInterests = (state) => state.profile.profile.profileData.interests;

export const getUserCurrencies = createSelector(getUserHub, (hub) => {
  if (hub) {
    return CURRENCIES_BY_HUB[hub];
  }
  return ALL_CURRENCIES;
});

export const getUserDocuments = createSelector(getUserHub, (hub) => getDocuments(hub));

export const getUserAllowedAccountCreation = createSelector(getUser, (user) =>
  get(user, 'allowAccountCreation', true),
);

export const getUserIsVerified = createSelector(
  getUserStatus,
  (status) =>
    status === VerificationStatuses.APPROVED ||
    status === VerificationStatuses.ACTIVE ||
    status === VerificationStatuses.DORMANT ||
    status === VerificationStatuses.FUNDED,
);

export const getUserIsNew = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.NEW,
);

export const getUserIsPosted = createSelector(
  getUserStatus,
  (status) =>
    status === VerificationStatuses.POSTED || status === VerificationStatuses.AUTOMATIC_APPROVEMENT,
);

export const getUserIsRejected = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.REJECTED,
);

export const getUserIsLead2 = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.EDITING.LEAD2,
);

export const getUserIsLead3 = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.EDITING.LEAD3,
);

export const getUserIsLead4 = createSelector(
  getUserStatus,
  (status) => status === VerificationStatuses.EDITING.LEAD4,
);

export const getUserEmail = createSelector(getUser, (user) => user.email);

export const getUserVerificationProgress = createSelector(
  getUser,
  (user) => get(user, 'verification.activeStep', 0) * 25,
);

export const getUserName = createSelector(getUser, (user) => get(user, 'profile.firstName', ''));
export const getUserNameFirstLetter = createSelector(getUserName, (username) => {
  return username[0]?.toUpperCase();
});
export const getUserLastName = createSelector(getUser, (user) => get(user, 'profile.lastName', ''));

export const getUserFullName = createSelector(getUser, getUserEmail, (user, email) => {
  const firstName = get(user, 'profile.firstName', undefined);
  const lastName = get(user, 'profile.lastName', undefined);

  return firstName && lastName ? `${firstName} ${lastName}` : email;
});

export const getFullName = createSelector(getUser, (user) => {
  const firstName = get(user, 'profile.firstName', '');
  const lastName = get(user, 'profile.lastName', '');

  if (!firstName && !lastName) {
    return null;
  }

  return `${firstName} ${lastName}`;
});

export const getUserPhoneInfo = createSelector(
  getUser,
  getFormattedCountries,
  (user, countries) => {
    const phoneCountry = get(user, 'profile.phoneCountry', undefined);
    const phoneCountryInfo = countries.find((country) => country.name === phoneCountry);
    return (
      phoneCountry &&
      phoneCountryInfo && {
        phoneNumber: get(user, 'profile.phoneNumber', ''),
        phoneCountry: phoneCountryInfo,
      }
    );
  },
);

export const getUserVerificationStatus = createSelector(getUser, (user) =>
  get(user, 'verification.status', ''),
);

export const getUserCountry = createSelector(getUser, (user) => get(user, 'profile.country', ''));

export const getUserRole = createSelector(getUser, (user) => user.role);

export const getAllPresets = (state) => state.userManagement.presets;

export const getUserQuizProgress = createSelector(getUser, (user) => ({
  retailQuizProgress: user.retailQuizProgress,
  proQuizProgress: user.proQuizProgress,
}));

export const getUserPassedQuiz = createSelector(
  getUserQuizProgress,
  ({ retailQuizProgress, proQuizProgress }) =>
    get(retailQuizProgress, 'isPassed', false) || get(proQuizProgress, 'isPassed', false),
);

export const getUserPassedProQuiz = createSelector(
  getUserQuizProgress,
  (quizProgress) => get(quizProgress, 'wasProTry', false) && get(quizProgress, 'passed', false),
);

export const getIsUserQuizRequired = createSelector(
  getUserLanguage,
  (userLanguage) => userLanguage === '/au',
);

export const getIsAdminRole = createSelector(getUserRole, (role) => role === Roles.ADMIN);

export const getIsManagerRole = createSelector(getUserRole, (role) => role === Roles.MANAGER);

export const getIsClientRole = createSelector(getUserRole, (role) => role === Roles.CLIENT);

export const getIsCountryManager = createSelector(getUser, (user) => {
  const role = get(user, 'role', undefined);
  const managedCountries = get(user, 'managedCountries', []);

  return (
    role === Roles.MANAGER &&
    managedCountries.length === 1 &&
    MANAGED_COUNTRIES.includes(managedCountries[0])
  );
});

export const getIsCountryAdmin = createSelector(getUser, (user) => {
  const role = get(user, 'role', undefined);
  const managedCountries = get(user, 'managedCountries', []);

  return (
    role === Roles.ADMIN &&
    managedCountries.length === 1 &&
    MANAGED_COUNTRIES.includes(managedCountries[0])
  );
});

export const getIsGlobalAdmin = createSelector(getUser, (user) => {
  const role = get(user, 'role', undefined);
  const managedCountries = get(user, 'managedCountries', []);

  return role === Roles.ADMIN && managedCountries.length === 0;
});

export const getManagedCountries = createSelector(getUser, (user) => {
  return get(user, 'managedCountries', []);
});

export const getUserProfile = createSelector(getUser, (user) => {
  return user && user.profile ? user.profile : {};
});

export const getUserSignature = createSelector(getUserProfile, ({ signature = null }) => signature);

export const getUserPermissions = createSelector(getUser, (user) => {
  return user && user.permissions ? user.permissions : {};
});

export const getUserLocation = createSelector(getUserProfile, (profile) => {
  let location = '';
  location = LOCATION_FIELDS.reduce((prevField, curField, index) => {
    if (index === 0) {
      return prevField + `${profile[curField]}`;
    }
    return profile[curField] ? prevField + `, ${profile[curField]}` : prevField;
  }, location);
  return location;
});

export const isAustralian = (state) =>
  isEqual(get(state, 'auth.user.profile.country', ''), AUSTRALIA);

export const isSEA = (state) =>
  includes(SEA_COUNTRIES, get(state, 'auth.user.profile.country', ''));

export const isFirstLoginSinceRejection = createSelector(getUser, (user) =>
  get(user, 'verification.firstLoginSinceRejection', false),
);

export const getWasRejected = createSelector(getUser, (user) =>
  get(user, 'verification.wasRejected', false),
);
export const isThai = (state) => isEqual(get(state, 'auth.user.profile.country', ''), THAILAND);
// export const isSeychelles = (state) => isEqual(get(state, 'auth.user.profile.country', ''), SEYCHELLES);
export const isSeychelles = (state) =>
  isEqual(get(state, 'auth.user.profile.country', ''), 'Aruba');

export const getMfaSettings = createSelector(getUser, (user) => {
  return user && user.mfa ? user.mfa : {};
});

export const getCurrencyByCountry = (state) => {
  const hub = getUserHub(state);
  const country = getUserCountry(state);

  return getCurrencyByCountryUtil(hub, country);
};

export const getLeverageByHub = (state) => {
  const isPro = showLeverage(state);

  return getLeverageByHubUtil(isPro);
};

export const getUserTradingPlatforms = createSelector(getUser, isEnHub, (user, isEnHubUser) => {
  if (isProduction && !isEnHubUser) {
    return TRADING_PLATFORMS.filter((item) => item.value === 'mt4');
  }
  return TRADING_PLATFORMS;
});

export const getIsAllowedUpgradeToPro = createSelector(
  getUserHub,
  getUserCountry,
  getUserProStatus,
  getIsUserWholeSale,
  getIsRetailQuizPassed,
  getIsProQuizPassed,
  getIsProSigned,
  getUserEvidenceStatementStatus,
  (
    hub,
    country,
    isProUser,
    isWholesaleUser,
    isRetailQuizPassed,
    isProQuizPassed,
    isProSigned,
    userEvidenceStatementStatus,
  ) => {
    const isAu = hub === GLOBAL_PRIME;
    const isUserCountryWhitelisted = config.UPGRADE_TO_PRO_COUNTRIES.includes(country);
    const isUpgradeHidden = !(isAu && isUserCountryWhitelisted);

    if (isUpgradeHidden || isProUser || isWholesaleUser) {
      return false;
    }

    const isProQuizPassedWithoutSign = isProQuizPassed && !isProSigned;
    const isProQuizPassedAndSignedWithoutApprovedEvidences =
      isProQuizPassed &&
      isProSigned &&
      userEvidenceStatementStatus !== UPLOAD_EVIDENCE_STATUSES.APPROVED;
    const isRetailQuizPassedAndProQuizNot = isRetailQuizPassed && !isProQuizPassed;

    return (
      isProQuizPassedWithoutSign ||
      isProQuizPassedAndSignedWithoutApprovedEvidences ||
      isRetailQuizPassedAndProQuizNot
    );
  },
);

export const showVerificationStepLeverage = createSelector(
  getUser,
  getUserHub,
  isProSelected,
  (user, hub, isProSelectedValue) => {
    const isLeverageRestricted = get(user, 'isLeverageRestricted', false);
    const isPro = get(user, 'isPro', false);
    const isProQuizPassed = get(user, 'proQuizProgress.isPassed', false);
    const isWholesale = get(user, 'wholesale', false);
    return (
      (hub === 'GLOBAL_PRIME_X' ||
        isPro ||
        isWholesale ||
        (isProSelectedValue && isProQuizPassed)) &&
      !isLeverageRestricted
    );
  },
);

export const showLeverage = createSelector(
  getUser,
  getUserIsPro,
  getUserHub,
  (user, isPro, hub) => {
    const isLeverageRestricted = get(user, 'isLeverageRestricted', false);
    const isWholesale = get(user, 'wholesale', false);
    return (hub === 'GLOBAL_PRIME_X' || isPro || isWholesale) && !isLeverageRestricted;
  },
);

export const defaultLeverage = createSelector(getLeverageByHub, (leverages) => {
  return leverages[0].value;
});

export const getRestrictedDefaultLeverage = createSelector(
  getUser,
  getLeverageByHub,
  (user, leverages) => {
    const userLeverage = user.maximumLeverage && user.maximumLeverage.toString();
    const leverage = leverages.find((el) => el.value === userLeverage) || leverages[0];
    return leverage.value;
  },
);

export const getMaximumLeverageForCurrentUser = createSelector(
  getUser,
  getLeverageByHub,
  (user, leverages) => {
    return [Roles.ADMIN, Roles.MANAGER].includes(user.role)
      ? leverages
      : user.maximumLeverage
      ? leverages.slice(
          leverages.findIndex((item) => item.value === user.maximumLeverage.toString()),
          leverages.length,
        )
      : [];
  },
);

export const defaultPlatform = createSelector(getUser, (user) => {
  let platfrom = get(user, 'accountSettings.platform');

  return platfrom || DEFAULT_TRADING_PLATFORM;
});

export const getEmailNotificationsSettings = createSelector(getUser, (user) => {
  return get(user, 'emailNotificationsSettings', []);
});

export const showMigrateMyAccount = createSelector(getUserHub, getUserCountry, (hub, country) => {
  if (hub === GLOBAL_PRIME) {
    return config.MIGRATE_ACCOUNT_COUNTRIES_HIDE.indexOf(country) < 0;
  }
  return config.MIGRATE_ACCOUNT_COUNTRIES.indexOf(country) > 0;
});

export const getUserFollowerId = createSelector(getUser, (user) => user.inviteFollowerId);

export const getFusionPlusData = createSelector(getUser, (user) => get(user, 'gpCopy'));

export const getIsFusionPlus = createSelector(getUser, (user) => !!get(user, 'gpCopy.isJoin'));

export const getIsGpCopyBannerClosed = createSelector(getUser, (user) =>
  get(user, 'isGpCopyBannerClosed'),
);

export const allowCopyAccount = createSelector(getUserHub, getUser, (hub, user) => {
  if (hub === GLOBAL_PRIME_X) {
    return true;
  }

  return get(user, 'allowCopyAccount', false);
});

export const isUpgradeQuiz = createSelector(getUser, (user) => get(user, 'isUpgradeToPro'));

export const isEuropeanCountry = createSelector(
  getUser,
  getFormattedCountries,
  (user, countries) => {
    const profileCountry = get(user, 'profile.country', undefined);
    const countryInfo = countries.find((country) => country.name === profileCountry);
    return get(countryInfo, 'region') === 'Europe';
  },
);

export const getUserAccountsCountLimit = createSelector(getUser, (user) => {
  return get(user, 'allowUnlimitedAccounts', false) ? ACCOUNTS_LIMIT.VIP : ACCOUNTS_LIMIT.PLAIN;
});
export const getUserDemoAccountsCountLimit = createSelector(getUser, (user) => {
  return get(user, 'allowUnlimitedDemoAccounts', false)
    ? DEMO_ACCOUNTS_LIMIT.VIP
    : DEMO_ACCOUNTS_LIMIT.PLAIN;
});

export const getUserEmailAndFullName = createSelector(getUser, (user) => {
  return {
    fullName: `${get(user, 'profile.firstName', '')} ${get(user, 'profile.lastName', '')}`,
    email: get(user, 'email', undefined),
  };
});

export const showDepositAu = createSelector(getUserHub, isAustralian, (hub, isAustralian) => {
  return !(hub === 'GLOBAL_PRIME_X' || !isAustralian);
});

export const showIM = createSelector(getUserEmail, (email) => {
  return ALLOW_IM.has(email);
});

export const canManagerAddDeposit = createSelector(getUser, (user) => user.canAddDeposit);

export const allowToSetLeads = createSelector(getUser, (user) => user.allowToSetLeads);

export const getIsUserStateExist = createSelector(getUser, (user) =>
  get(user, 'profile.state', ''),
);

export const getUserAddressLine1 = createSelector(getUser, (user) =>
  get(user, 'profile.addressLine1', ''),
);

export const getIsUserAddressLineValid = createSelector(getUser, (user) => {
  const address = get(user, 'profile.addressLine1', '');
  return !/\b(?:p\.?\s*o\.?|post\s+office)(\s+)?(?:box|[0-9]*)?\b/i.test(address);
});

export const showHyperwallet = createSelector(getUserEmail, (email) => {
  return ALLOW_HYPERWALLET_TO_USERS.has(email);
});

export const showDepositMonoova = createSelector(
  getUserHub,
  isAustralian,
  (hub, isUserAustralian) => {
    return hub === 'GLOBAL_PRIME' && isUserAustralian;
  },
);

export const isUserFromBannedCountry = createSelector(
  getUser,
  getFormattedCountries,
  (user, countries) => {
    if (!user?.profile || !countries?.length) {
      // checking for view before initialization
      // otherwise users from non-blocked country can see infobox for blocked country
      // or users from blocked country can use functionality for non-blocked country
      return;
    }

    if (user?.ECPUser) {
      return false;
    }

    const bannedCountry = countries.find(({ name }) => name === user.profile.country)
      ?.bannedCountry;
    const restrictedState = isStateRestricted(user.profile, countries);

    return !!(bannedCountry || restrictedState);
  },
);

export const getAllowMigrateMyAccount = createSelector(
  getUserHub,
  getUserCountry,
  getUserEmail,
  getUserStatus,
  (hub, country, userEmail, status) => {
    // https://coherentsolutions.atlassian.net/browse/FGD-1465
    return false;
    // if (hub === GLOBAL_PRIME_X) {
    //   return false;
    // }
    // return isMigrationAllowed(country, userEmail, status);
  },
);

export const TIME_ZONES = {
  AEST: { label: 'AEST', time: 10 },
  EET: { label: 'EET', time: 2 },
};

export const getTimeZoneByHub = createSelector(getUserHub, (hub) => {
  return hub === GLOBAL_PRIME_X ? TIME_ZONES.EET : TIME_ZONES.AEST;
});

export const getUserCurrenciesByCountry = createSelector(
  getUserHub,
  getUserCountry,
  (hub, userCountry) => {
    if (hub) {
      return CURRENCIES_BY_HUB[hub];
    } else {
      return [];
    }
  },
);

const getCountry = (_, userCountry) => userCountry;

export const getUserCurrenciesByCountryByAdmin = createSelector(
  getUserHub,
  getCountry,
  (hub, userCountry) => {
    if (hub) {
      return CURRENCIES_BY_HUB[hub];
    } else {
      return [];
    }
  },
);
