import { createAction } from 'redux-actions';

export const getVerificationChecklistQuestionsRequest = createAction(
  'GET_VERIFICATION_CHECKLIST_QUESTIONS_REQUEST',
);
export const getVerificationChecklistQuestionsSuccess = createAction(
  'GET_VERIFICATION_CHECKLIST_QUESTIONS_SUCCESS',
);
export const getVerificationChecklistQuestionsFail = createAction(
  'GET_VERIFICATION_CHECKLIST_QUESTIONS_FAIL',
);

export const setUserVerificationChecklistAnswersRequest = createAction(
  'SET_USER_VERIFICATION_CHECKLIST_ANSWERS_REQUEST',
);
export const setUserVerificationChecklistAnswersSuccess = createAction(
  'SET_USER_VERIFICATION_CHECKLIST_ANSWERS_SUCCESS',
);
export const setUserVerificationChecklistAnswersFail = createAction(
  'SET_USER_VERIFICATION_CHECKLIST_ANSWERS_FAIL',
);

export const setAccountVerificationChecklistAnswersRequest = createAction(
  'SET_ACCOUNT_VERIFICATION_CHECKLIST_ANSWERS_REQUEST',
);
export const setAccountVerificationChecklistAnswersSuccess = createAction(
  'SET_ACCOUNT_VERIFICATION_CHECKLIST_ANSWERS_SUCCESS',
);
export const setAccountVerificationChecklistAnswersFail = createAction(
  'SET_ACCOUNT_VERIFICATION_CHECKLIST_ANSWERS_FAIL',
);

export const openChecklist = createAction('OPEN_CHECKLIST');
export const updateChecklist = createAction('UPDATE_CHECKLIST');
export const setChecklistData = createAction('SET_CHECKLIST_DATA');
