import React from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import {
  getIsAdminRole,
  getIsManagerRole,
  getIsCountryManager,
  getIsAuthenticated,
  getUserHub,
  getIsImpersonation,
} from 'modules/auth/selectors';
import { useSelector } from 'react-redux';

import { Routes } from 'constants/routeConstants';
import { getAvailableRoutes, getMenuItems } from 'modules/menu/selectors';
import { LOGOUT_REASON, RETURN_URL_STORAGE_KEY } from '../auth/constants';

const validPath = (menuItem, pathname) => {
  const iPath = menuItem.routePath || menuItem.path;
  return matchPath(pathname, iPath);
};

const Home = () => {
  const isManager = useSelector(getIsManagerRole);
  const isAdmin = useSelector(getIsAdminRole);
  const isCountryManager = useSelector(getIsCountryManager);
  const isImpersonation = useSelector(getIsImpersonation);

  const menuItemsList = useSelector(getMenuItems);
  const availableRoutes = useSelector(getAvailableRoutes);
  const hub = useSelector(getUserHub);
  const authenticated = useSelector(getIsAuthenticated);

  let returnUrlInfo = sessionStorage.getItem(RETURN_URL_STORAGE_KEY);
  returnUrlInfo = returnUrlInfo ? JSON.parse(returnUrlInfo) : null;

  let path = Routes.MY_ACCOUNTS;

  if (!authenticated) {
    path = Routes.SIGNIN;
  } else if (returnUrlInfo?.pathname && returnUrlInfo?.pathname !== '/' && !isImpersonation) {
    path = returnUrlInfo.pathname + returnUrlInfo.search;
    //FM comment: avoid removing storage when url does not contain hub, issue with component reloading
    //TODO: remove after package connected-react-router will be removed and rewrite on other solution for redirections from saga
    if (hub && window.location.href.includes(hub)) {
      sessionStorage.removeItem(RETURN_URL_STORAGE_KEY);
    }
  } else if (isAdmin || isCountryManager) {
    path = Routes.DASHBOARD;
  } else if (isManager) {
    path = Routes.VERIFICATION_MANAGEMENT;
  }

  const pathAvailable = availableRoutes.some((route) => validPath(route, path));

  if (!pathAvailable) {
    path = menuItemsList[0]?.path || {
      pathname: Routes.SIGNIN,
      state: { reason: LOGOUT_REASON.AUTH_FAIL },
    };
  } else if (path === Routes.SIGNIN) {
    path = {
      pathname: Routes.SIGNIN,
      state: { reason: LOGOUT_REASON.AUTH_FAIL },
    };
  }

  return <Redirect to={path} />;
};

export default Home;
