import { combineActions, handleActions } from 'redux-actions';
import get from 'lodash/get';

import { getUserSuccess, updateUserDetailsSuccess } from 'modules/user/actions';
import { getVerificationChecklistQuestionsSuccess, setChecklistData } from '../actions';
import { VERIFICATION_CHECK_QUESTION_TYPES } from '../constants';

const defaultState = {
  userChecklist: [],
  userChecklistData: [],
  accountChecklist: [],
  accountChecklistData: [],
};

export default handleActions(
  {
    [getVerificationChecklistQuestionsSuccess](state, action) {
      return {
        ...state,
        ...(action.payload.type === VERIFICATION_CHECK_QUESTION_TYPES.NEW_ACCOUNT
          ? { userChecklist: action.response.data }
          : { accountChecklist: action.response.data }),
      };
    },
    [setChecklistData](state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    [combineActions(getUserSuccess, updateUserDetailsSuccess)](state, action) {
      return {
        ...state,
        ...{
          userChecklistData: get(action, 'response.data.verification.verificationChecklist', []),
        },
      };
    },
  },
  defaultState,
);
