import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

import Notifications from 'modules/notifications';
import Lpoa from 'modules/lpoa';

import SideMenu from 'modules/menu';
import HeaderDesktop from 'modules/common/HeaderDesktop';
import ErrorBoundary from 'modules/errorBoundary';
import RejectionModal from 'modules/accounts/pages/MyAccounts/containers/RejectionModalContainer';
import BottomNavigation from 'modules/menu/components/BottomNavigation';
import { isSidebarCollapsed } from 'modules/menu/components/SideMenu/selectors';
import { useMediaQuery } from 'material-latest';
import isMobile from 'utils/isMobile';
import { WITHDRAWAL2_PATH, WITHDRAWAL_PATH } from 'constants/routeConstants';
import AML from 'modules/common/AML/containers/AMLContainer';

const MainLayout = ({
  children,
  classes,
  title,
  warning,
  desktopContent,
  contentClassName,
  headerClassName,
  isAuthenticated,
  isPublic,
  hideMessage,
  userRole,
  hub,
  userCountry,
  isPro,
}) => {
  const isCollapsed = useSelector(isSidebarCollapsed);
  const isDesktop = useMediaQuery('(min-width: 1025px)');
  const { pathname } = useLocation();

  return (
    <div className={classes.root}>
      <Notifications className={classes.notifications} />
      <Lpoa />
      <div className={classNames(classes.container, { [classes.publicContainer]: isPublic })}>
        {isDesktop && <SideMenu isAuthenticated={isAuthenticated} isPublic={isPublic} />}
        <div
          className={classNames(classes.mainComponent, {
            [contentClassName]: !!contentClassName,
            [classes.publicContent]: isPublic,
            [classes.mainComponentMobile]: isMobile(),
            [classes.sideMenuCollapsed]: isCollapsed,
          })}>
          <div className={classes.headerDesktop}>
            <HeaderDesktop
              title={title}
              className={headerClassName}
              warning={warning}
              hideMessage={hideMessage}
              isPublic={isPublic}>
              {desktopContent}
            </HeaderDesktop>
          </div>
          <ErrorBoundary message="Sorry! There has been an issue and we can't display your page at the moment. Please contact support for assistance if you need this information">
            <div className={classes.mainLayoutContent}>{children}</div>
          </ErrorBoundary>
        </div>
      </div>
      {isPublic || isDesktop ? null : (
        <BottomNavigation
          isAuthenticated={isAuthenticated}
          isPublic={isPublic}
          userRole={userRole}
          hub={hub}
          userCountry={userCountry}
          isPro={isPro}
        />
      )}
      <RejectionModal />
      {!(pathname === WITHDRAWAL_PATH || pathname === WITHDRAWAL2_PATH) ? <AML /> : null}
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  mobileContent: PropTypes.object,
  desktopContent: PropTypes.object,
  title: PropTypes.string.isRequired,
  warning: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  userRole: PropTypes.string,
  hub: PropTypes.string,
  userCountry: PropTypes.string,
  isPro: PropTypes.bool,
};

export default withStyles(styles)(MainLayout);
