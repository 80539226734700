export const FOLLOW_UPS_FORM = 'FOLLOW_UPS_FORM';
export const SORT_FIELDS = ['dateAsc', 'statusAsc', 'callStatusAsc'];
export const DEFAULT_HIDDEN_COLUMNS = [];
export const COLUMNS_HIDE = ['status', 'callStatus', 'lastContact', 'managerId'];
export const COLUMNS_NEEDS_COMPACT = ['status', 'callStatus', 'lastContact', 'managerId'];
export const LAST_CONTACT_RANGES = [
  { value: 'lastContactHide', label: 'Hide' },
  { value: '', label: 'Show all' },
  { value: '[0, 4]', label: '0 - 4 days' },
  { value: '[5, 9]', label: '5 - 9 days' },
  { value: '[10, 14]', label: '10 - 14 days' },
  { value: '[15, 20]', label: '15 - 20 days' },
  { value: '[21, -1]', label: '21 days +' },
];
export const NEED_TO_PARSE = ['lastContact'];
