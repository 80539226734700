import { all, put, takeEvery, select } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'modules/reduxNavigation/actions';
import queryString from 'query-string';
import {
  change,
  getFormMeta,
  destroy,
  actionTypes as reduxFormActionTypes,
  reset,
  isValid,
  submit,
} from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import * as actionCreators from '../actions';
import * as notificationsActions from 'modules/notifications/actions';
import * as selectors from '../selectors';
import { PAYMENT_METHODS } from 'constants/index';
import { DEPOSIT_PATH, Routes } from 'constants/routeConstants';
import { DEPOSIT_CARD_FORM, DEPOSIT_SMARTWAY_FORM } from '../constants';
import { depositCryptoDay1 } from '../actions';

function* paymentsDepositSuccess() {
  yield all([put(push(Routes.CONFIRM_PAYMENT)), put(actionCreators.searchDepositHistoryRequest())]);
}

function* paymentsDepositMonoovaSuccess() {
  yield all([put(push(Routes.PAY_ID_PAYMENT)), put(actionCreators.searchDepositHistoryRequest())]);
}

function* paymentsDepositFinmoSuccess() {
  yield all([put(push(Routes.PAY_ID_PAYMENT)), put(actionCreators.searchDepositHistoryRequest())]);
}

function* paymentsDepositFail(action) {
  const errorMessage = get(action, 'error.data.message', 'Error');
  yield all([put(notificationsActions.showNotificationError(errorMessage))]);
}

function* paymentsSmartwaySuccess(action) {
  const { payload } = action;
  yield all([
    put(reset(DEPOSIT_SMARTWAY_FORM)),
    put(push(Routes.PAYMENTS_SUCCESS + payload.accountId)),
    put(actionCreators.searchDepositHistoryRequest()),
    put(
      change(DEPOSIT_SMARTWAY_FORM, {
        accountId: payload.accountId,
      }),
    ),
  ]);
}

function* preselectAccount(action) {
  yield put(change(DEPOSIT_CARD_FORM, { accountId: action.payload }));
}

function* confirmPaymentInit() {
  const redirectUrl = yield select(selectors.getRedirectUrl);

  if (!redirectUrl) {
    yield put(push(DEPOSIT_PATH));
  }
}

function* copiedBankwireInfo() {
  yield all([
    put(
      notificationsActions.showNotificationInfo({ key: 'payments:deposit.notifications.copied' }),
    ),
  ]);
}

function* cleanDepositForm({ payload }) {
  const { pathname } = payload;
  if (pathname !== Routes.PAYMENTS && pathname !== Routes.CONFIRM_PAYMENT) {
    const depositFormMeta = yield select(getFormMeta(DEPOSIT_CARD_FORM));
    if (!isEmpty(depositFormMeta)) {
      yield put(destroy(DEPOSIT_CARD_FORM));
    }
  }
}

function* changeAccounts({ meta: { form, field } }) {
  if (form === DEPOSIT_CARD_FORM) {
    if (field === 'currency' || field === 'accountId' || field === 'amount') {
      yield reloadConversionRate();
    }
  }
}

function* reloadConversionRate() {
  const fromCurrency = yield select(selectors.getFromCurrency);
  const toCurrency = yield select(selectors.getToCurrency);
  const selectedPaymentMethod = yield select(selectors.getSelectedMethod);

  const isFeeImposed = selectedPaymentMethod === PAYMENT_METHODS.local;

  if (fromCurrency && toCurrency && fromCurrency !== toCurrency) {
    const payload = isFeeImposed
      ? { fromCurrency, toCurrency, isFeeImposed }
      : { fromCurrency, toCurrency };

    yield put(actionCreators.getConversionRateDepositRequest(payload));
  }
}

function* paymentsDepositMonoova(action) {
  const { payload } = action;
  const formValid = yield select(isValid(DEPOSIT_CARD_FORM));
  if (!formValid) {
    yield put(submit(DEPOSIT_CARD_FORM));
    return;
  }

  if (payload.isBankTransfer) {
    yield put(
      push({
        pathname: Routes.BANK_TRANSFER_PAYMENT,
        search: queryString.stringify({ currency: payload.currency }),
      }),
    );
    return;
  }

  yield put(actionCreators.paymentsDepositMonoovaRequest(payload));
}

function* paymentsDepositFinmo(action) {
  const { payload } = action;
  const formValid = yield select(isValid(DEPOSIT_CARD_FORM));
  if (!formValid) {
    yield put(submit(DEPOSIT_CARD_FORM));
    return;
  }

  if (payload.isBankTransfer) {
    yield put(
      push({
        pathname: Routes.BANK_TRANSFER_PAYMENT,
        search: queryString.stringify({ currency: payload.currency }),
      }),
    );
    return;
  }

  yield put(actionCreators.paymentsDepositFinmoRequest(payload));
}

function* handleDepositCryptoDay1(action) {
  const formValid = yield select(isValid(DEPOSIT_CARD_FORM));
  if (!formValid) {
    yield put(submit(DEPOSIT_CARD_FORM));
    return;
  }

  yield put(actionCreators.setCryptoDay1SelectedAccount(action.payload));
  yield put(push(Routes.CRYPTO_DAY1_DEPOSIT));
}

export default function* watchRequest() {
  yield all([
    takeEvery(reduxFormActionTypes.CHANGE, changeAccounts),
    takeEvery(
      [
        actionCreators.paymentsDepositIsignthisSuccess,
        actionCreators.paymentsDepositScbSuccess,
        actionCreators.paymentsDepositPaymentAsiaSuccess,
        actionCreators.paymentsDepositPraxisSuccess,
      ],
      paymentsDepositSuccess,
    ),
    takeEvery(actionCreators.paymentsDepositFinmoSuccess, paymentsDepositFinmoSuccess),
    takeEvery(actionCreators.paymentsDepositSmartwaySuccess, paymentsSmartwaySuccess),
    takeEvery(
      [
        actionCreators.paymentsDepositSmartwayFail,
        actionCreators.paymentsDepositScbFail,
        actionCreators.paymentsDepositPaymentAsiaFail,
        actionCreators.paymentsDepositPraxisFail,
        actionCreators.paymentsDepositFinmoFail,
        actionCreators.paymentsDepositMonoovaFail,
      ],
      paymentsDepositFail,
    ),
    takeEvery(actionCreators.confirmPaymentInit, confirmPaymentInit),
    takeEvery(actionCreators.copyBankwireInfo, copiedBankwireInfo),
    takeEvery(actionCreators.preselectAccount, preselectAccount),
    takeEvery(actionCreators.paymentsDepositFinmo, paymentsDepositFinmo),
    takeEvery(LOCATION_CHANGE, cleanDepositForm),
    takeEvery(actionCreators.paymentsDepositMonoovaSuccess, paymentsDepositMonoovaSuccess),
    takeEvery(actionCreators.paymentsDepositMonoova, paymentsDepositMonoova),
    takeEvery(depositCryptoDay1, handleDepositCryptoDay1),
  ]);
}
