import { combineReducers } from 'redux';

import paymentOptions from './paymentOptions';
import history from './history';
import hyperwallet from './hyperwallet';
import bankDetails from './bankDetails';
import localBank from './localBank';
import finrax from './finrax';
import day1Withdrawal from './day1Withdrawal';

const withdraw = combineReducers({
  paymentOptions,
  history,
  hyperwallet,
  bankDetails,
  localBank,
  finrax,
  day1Withdrawal,
});

export default withdraw;
