import React, { Suspense, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MainLayout from 'modules/common/MainLayout';
import DocumentTitle from 'react-document-title';
import Breadcrumbs from 'modules/common/Breadcrumbs';
import { DEPOSIT_PATH } from 'constants/routeConstants';
import copyTextToClipboard from 'utils/copyTextToClipboard';
import * as notificationsActions from 'modules/notifications/actions';
import BankTransfer from '../components/BankTransferPaymentCredentials';
import { BANK_TRANSFER, BANK_TRANSFER_AU } from '../constants';
import { showDepositAu } from 'modules/auth/selectors';

const ConfirmRealTimePaymentContainer = () => {
  const { t } = useTranslation('payments');
  const dispatch = useDispatch();
  const isAuHub = useSelector(showDepositAu);
  const { currency: currencySearch = 'usd' } = queryString.parse(useLocation().search);

  const bankTransferData = isAuHub ? BANK_TRANSFER_AU : BANK_TRANSFER;
  const information = bankTransferData[currencySearch] || bankTransferData.usd;

  const copyToBuffer = useCallback(
    (value) => {
      copyTextToClipboard(value);
      dispatch(notificationsActions.showNotificationInfo(t('deposit.notifications.copied')));
    },
    [dispatch, t],
  );
  const currency = currencySearch.toUpperCase();

  return (
    <MainLayout title={t('deposit.confirmPayment.title')}>
      <DocumentTitle title={t('deposit.confirmPayment.title')} />
      <Breadcrumbs
        routes={[
          { label: t('deposit.deposit'), link: DEPOSIT_PATH },
          {
            label: t('deposit.bankTransfer.title', { currency }),
            link: '',
          },
        ]}
      />
      <Suspense>
        <BankTransfer currency={currency} bankTransferDataArr={information} onCopy={copyToBuffer} />
      </Suspense>
    </MainLayout>
  );
};

export default ConfirmRealTimePaymentContainer;
