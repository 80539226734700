import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import last from 'lodash/last';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { Routes } from 'constants/routeConstants';
import getAuthData from 'modules/auth/helpers/getAuthData';
import { signinNonfxSuccess } from 'modules/auth/pages/SignIn/actions';
import { LOGOUT_REASON } from 'modules/auth/constants';
import { FOLLOWER_HUB_COOKIE_NAME, FOLLOWER_ID_COOKIE_NAME } from '../../../constants';

const PrivateRoute = ({
  isAuthenticated,
  adminOnly,
  isAdmin,
  allAuthorized,
  component,
  ...props
}) => {
  const search = useLocation().search;
  const preAuthData = getAuthData(search);
  const dispatch = useDispatch();

  const RouteComponent = <Route path={props.path} {...props} component={component} />;

  /*
    Probably outdated code section: SignIn after successful signUp from Fusion-market.com | NonFX website
  */
  if (preAuthData) {
    const { _id, token, refreshToken } = preAuthData;

    dispatch(
      signinNonfxSuccess({
        token: token,
        refreshToken: refreshToken,
        user: { _id: _id, role: 'client' },
      }),
    );

    return RouteComponent;
  }

  if (adminOnly) {
    return isAdmin ? (
      RouteComponent
    ) : (
      <Redirect to={{ pathname: Routes.SIGNIN, state: { reason: LOGOUT_REASON.AUTH_FAIL } }} />
    );
  }

  if (!isAuthenticated) {
    const path = '/' + last(props.path.split('/'));
    if (path === Routes.PRIME_PLUS_FOLLOWER_JOIN) {
      const token = last(props.location.pathname.split('/'));
      const query = queryString.parse(props.location.search);
      Cookies.set(FOLLOWER_ID_COOKIE_NAME, token, { sameSite: 'none', secure: true });
      if (query.followerHub) {
        Cookies.set(FOLLOWER_HUB_COOKIE_NAME, query.followerHub, {
          sameSite: 'none',
          secure: true,
        });
      }
    }
    return (
      <Redirect to={{ pathname: Routes.SIGNIN, state: { reason: LOGOUT_REASON.AUTH_FAIL } }} />
    );
  }

  if (allAuthorized) {
    return RouteComponent;
  }

  return RouteComponent;
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  staffOnly: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isManager: PropTypes.bool,
  allAuthorized: PropTypes.bool,
  allowAnonymous: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]),
};

export default PrivateRoute;
