import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'material-latest';
import CloseIcon from '@mui/icons-material/Close';

import TPIconButton from 'components/TP-UI/TPIconButton';
import { SIZES } from 'components/TP-UI/constants';

import styles from './styles';

const TPSelectSelectedValue = ({
  value,
  multiple = false,
  renderValue,
  placeholder = '',
  options = [],
  optionValue = 'value',
  optionLabel = 'label',
  grouped,
  clearable = false,
  disabled = false,
  returnsObject = false,
  onChange,
}) => {
  const hasEmptyOption = useMemo(
    () => options?.some((option) => option[optionValue] === '' || option[optionValue] === null),
    [options, optionValue],
  );

  const showPlaceholder =
    (!hasEmptyOption && (value === undefined || value === null || value === '')) ||
    (Array.isArray(value) && value.length === 0);

  //MUI v4 handle this event to show dropdown, need to prevent it when user clicks on clear button
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onChange) {
      onChange(multiple ? [] : null);
    }
  };
  const displayedValue = useMemo(() => {
    let opts = options;
    if (grouped) {
      opts = options.reduce((arr, group) => {
        return [...arr, ...group.options];
      }, []);
    }
    const selected = opts?.filter((option) =>
      Array.isArray(value)
        ? value.some((val) => option[optionValue] === val)
        : option[optionValue] === value,
    );

    return renderValue
      ? renderValue(multiple ? selected : selected && selected[0])
      : multiple
      ? selected.map((val) => val[optionLabel]).join(', ')
      : (selected.length > 0 && selected[0][optionLabel]) ||
        (returnsObject && value?.[optionLabel] ? value[optionLabel] : value);
  }, [options, grouped, renderValue, multiple, optionLabel, returnsObject, value, optionValue]);

  // TODO: Add a check for the value to be an object
  return (
    <Box sx={styles.valueContainer}>
      <Box sx={[styles.value, showPlaceholder && styles.placeholder]}>
        {showPlaceholder
          ? placeholder
          : displayedValue?.value
          ? displayedValue?.value
          : displayedValue}
      </Box>
      {clearable && !showPlaceholder && (
        <TPIconButton
          size={SIZES.XSMALL}
          disabled={disabled}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
          onClick={handleClick}>
          <CloseIcon fontSize={SIZES.SMALL} />
        </TPIconButton>
      )}
    </Box>
  );
};

TPSelectSelectedValue.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array,
  optionValue: PropTypes.string, //key in option object
  optionLabel: PropTypes.string, //key in option object
  placeholder: PropTypes.node,
  multiple: PropTypes.bool,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  renderValue: PropTypes.func,
  onChange: PropTypes.func,
};

export default TPSelectSelectedValue;
