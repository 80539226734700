import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TPTypography from 'components/TP-UI/TPTypography';
import formatAmount from 'utils/formatAmount';

const AccountBalanceWithCurrency = (props) => {
  const { currency = '', value = '' } = props;
  const { t } = useTranslation('payments');

  return value ? (
    <>
      <TPTypography color="secondary" component="span" variant="body3">
        {t('common:labels.currentBalance')}
      </TPTypography>
      <TPTypography component="span" variant="body3">
        {formatAmount(value, currency)}
      </TPTypography>
    </>
  ) : null;
};

AccountBalanceWithCurrency.propTypes = {
  currency: PropTypes.string,
  value: PropTypes.string,
};

export default AccountBalanceWithCurrency;
