export const colors = {
  brightGreen: {
    dark: '#C0F826',
    main: '#D5FA6E',
    light: '#E0FC93',
    lighter: '#EAFDB7',
    lightest: '#EFFAD0',
    lightest2: 'rgba(192, 248, 38, 0.1)',
  },
  blue: {
    dark: '#110D3D',
    main: '#1E176A',
    light: '#2A2097',
    lighter: '#372AC4',
    lightest: '#574BD9',
  },
  // fm color
  gray: {
    darker: '#515581',
  },
  grey1: {
    dark: '#9EA1B9',
    main: '#AAADC2',
    light: '#B6B9CB',
    lighter: '#C2C4D3',
    lightest: '#CFD0DC',
  },
  grey2: {
    dark: '#1C1B1F',
    main: '#37353D',
    light: '#53505B',
    lighter: '#6E6A7A',
    lightest: '#8A8696',
  },
  grey3: {
    dark: '#DADFEC',
    main: '#DFE3EE',
    light: '#E3E7F1',
    lighter: '#E3E7F1',
    lightest: '#ECEFF6',
  },
  green: {
    dark: '#80CA8A',
    main: '#90D199',
    light: '#A0D7A7',
    lighter: '#B0DEB6',
    lightest: '#C0E4C4',
  },
  red: {
    dark: '#FE6B6B',
    main: '#FE7E7E',
    light: '#FE9090',
    lighter: '#FEA3A3',
    lightest: '#FFB5B5',
  },
  orange: {
    dark: '#F2994A',
    main: '#F4A661',
    light: '#F5B377',
    lighter: '#F7BF8E',
    lightest: '#F8CCA5',
  },
  yellow: {
    darkest: '#BF9900',
    darker: '#CCA300',
    dark: '#E6B800',
    main: '#FFCC00',
    light: '#FFEFB0',
    lighter: '#FFF7D9',
    lightest: '#FFFAE6',
  },
  white: '#FFFFFF',
};
