export default {
  pdfPreview: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    width: '85%',
    height: '80%',
    margin: 'auto',
  },
  warningMessage: {
    top: '50%',
    position: 'relative',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
  },
};
