import get from 'lodash/get';
import { stopSubmit } from 'redux-form';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import * as actionCreators from '../actions';
import { MFA_INPUT_FORM } from '../constants';
import { redirectToDashboard } from '../../SignIn/sagas';
import { getUpdatedUserRequest } from 'modules/menu/actions';
import { getUserId } from 'modules/auth/selectors';

function* showError(action) {
  const { message: errorMessage } = get(action, 'error.data', {});

  yield put(
    stopSubmit(MFA_INPUT_FORM, {
      _error: errorMessage,
    }),
  );
}

function* validateMfaSuccess() {
  const userId = yield select(getUserId);

  yield put(getUpdatedUserRequest(userId));
  yield call(redirectToDashboard);
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.validateMfaSuccess, validateMfaSuccess),
    takeEvery(actionCreators.validateMfaFail, showError),
  ]);
}
