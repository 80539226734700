import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, withStyles } from '@material-ui/core';

import formatPrice from 'utils/formatPrice';
import styles from './styles';

function AccountBalance({ currency, amount, classes, conversion = 0, conversionCurrency }) {
  const { t } = useTranslation('payments');

  return (
    <Typography className={classes.text}>
      {t('common:labels.currentBalance')}
      <Typography component="span" className={classes.amount} inline>
        {formatPrice(amount, currency)}
      </Typography>
      {conversionCurrency && !!conversion && (
        <Typography component="span" className={classes.amount} inline>
          {' '}
          ({formatPrice(conversion, conversionCurrency)})
        </Typography>
      )}
    </Typography>
  );
}

AccountBalance.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  conversion: PropTypes.number,
  conversionCurrency: PropTypes.string,
};

export default withStyles(styles)(AccountBalance);
