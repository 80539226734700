import { combineReducers } from 'redux';

import deposit from '../pages/Deposit/reducers';
import withdraw from '../pages/Withdraw/reducers';
import transfer from '../pages/Transfer/reducers';
import withdraw2 from '../pages/Withdraw2/reducers';
import withdrawalHistory from '../pages/WithdrawalHistory/reducers';
import paymentsPermissions from './paymentsPermissions';
import day1User from './day1User';
import cryptoDay1 from './cryptoDay1';

const reducer = combineReducers({
  deposit,
  paymentsPermissions,
  withdraw,
  transfer,
  withdraw2,
  withdrawalHistory,
  day1User,
  cryptoDay1,
});

export default reducer;
