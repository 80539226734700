import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import CustomModal from 'modules/common/CustomModal';
import PrimaryButton from 'modules/common/PrimaryButton';
import styles from './styles';
import { closeModal } from 'modules/common/CustomModal/actions';
import formatDate from 'utils/formatDate';
import TextInput from 'modules/common/TextInput/containers/TextInputContainer';
import { checkNonLatin, required } from 'utils/validation/fieldValidationRules';
import * as lpoaActions from '../../actions/index';
import * as authSelectors from 'modules/auth/selectors';
import { LPOA_DOCUMENT_MODAL, LPOA_DOCUMENT_FORM } from '../../constants';
import { DATE_FORMAT_YYYYMMDD } from 'constants/index';
import LpoaTerms from '../LpoaTerms';
import { getLpoaInfo } from '../../selectors';
import Picker from 'modules/common/Picker';
import * as accountsSelectors from 'modules/accounts/pages/MyAccounts/selectors';
import * as notificationsActions from 'modules/notifications/actions';

let WelcomeModal = ({
  classes,
  upgradeFormValues,
  actions,
  username,
  fullName,
  lpoaInfo,
  accounts,
}) => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(authSelectors.getIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(lpoaActions.getLpoaRequest());
    }
  }, [dispatch, isAuthenticated]);

  const listAccounts = accounts.map((account) => ({
    label: account.mt4login,
    value: account.mt4login,
  }));

  const isDisable = () => {
    if (!upgradeFormValues) {
      return true;
    }
    return !upgradeFormValues.userAccount || !upgradeFormValues.sign;
  };

  const submit = () => {
    actions.signLpoaRequest({
      sign: upgradeFormValues.sign,
      userAccount: upgradeFormValues.userAccount,
    });
    dispatch(closeModal(LPOA_DOCUMENT_MODAL));
    dispatch(
      notificationsActions.showNotificationInfo('Limited Power of Attorney successfully signed'),
    );
  };

  return (
    <CustomModal
      modalName={LPOA_DOCUMENT_MODAL}
      className={classes.welcomeModal}
      isCloseButtonNeeded>
      <p className={classes.title}>LIMITED POWER OF ATTORNEY</p>
      <div className={classes.line} />
      <p className={classes.content}>{`Hi ${username}`}</p>
      <p
        className={
          classes.content
        }>{`It looks like you wish to appoint ${lpoaInfo.attorney} (${lpoaInfo.attorneyAccount}) with a Limited Power of Attorney (LPOA) over your trading account. If you wish to proceed, please carefully read the below information and sign if accepting.`}</p>
      <p className={classes.content}>
        {`This Power of Attorney is granted on the ${
          lpoaInfo.createdAt ? formatDate(lpoaInfo.createdAt, DATE_FORMAT_YYYYMMDD, true) : ''
        }.`}
      </p>
      <p className={classes.content}>{`I, ${fullName} hereby appoint ${lpoaInfo.attorney}`}</p>
      <p className={classes.content}>To be the Customer’s Attorney for the following purposes:</p>
      <div className={classes.termsBox}>
        <LpoaTerms fee={lpoaInfo.fee} />
      </div>
      <p className={classes.content}>THE CUSTOMER</p>
      <p className={classes.content}>{fullName}</p>
      <Field
        name="userAccount"
        label="My Trading Account Number:"
        component={Picker}
        values={listAccounts}
        placeholder="Account Number"
        validate={required}
      />
      <Field
        name="sign"
        label={t('upgradeToPro:sign.label')}
        placeholder={t('upgradeToPro:sign.placeholder')}
        type="text"
        component={TextInput}
        inputClassName={classes.textField}
        validate={[checkNonLatin, required]}
      />
      <p
        className={
          classes.content
        }>{`I Authorise ${lpoaInfo.attorney} to act on my behalf and fully accept the above terms.`}</p>

      <PrimaryButton
        color="primary"
        onClick={submit}
        label="Sign"
        className={classes.migrateButton}
        disabled={isDisable()}
      />
    </CustomModal>
  );
};

WelcomeModal = reduxForm({ form: LPOA_DOCUMENT_FORM })(WelcomeModal);
WelcomeModal = connect(
  (state) => ({
    upgradeFormValues: getFormValues(LPOA_DOCUMENT_FORM)(state),
    username: authSelectors.getUserName(state),
    fullName: authSelectors.getUserFullName(state),
    lpoaInfo: getLpoaInfo(state),
    accounts: accountsSelectors.getLiveAccountsAsOptions(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators(lpoaActions, dispatch),
  }),
)(WelcomeModal);
export default withStyles(styles)(WelcomeModal);
