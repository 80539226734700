import React from 'react';
import { Typography } from '@material-ui/core';
import { Grid, Stack } from 'material-latest';
import IconButton from '@material-ui/core/IconButton';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';

const BankTransferCredentialsSepa = ({ bankTransferData, handleCopy, t, classes }) => {
  return (
    <>
      <Grid container gap={2}>
        <Grid item xs={12} md="auto">
          <Typography className={classes.termsContainerKey}>
            {t('deposit.bankTransfer.bankAddress')}
          </Typography>
          <Stack direction="row" gap={1}>
            <Typography className={classes.termsContainerValue}>
              {bankTransferData.bankAddress}
            </Typography>
            <IconButton
              onClick={handleCopy(bankTransferData.bankAddress)}
              className={classes.copyButton}>
              <FileCopyOutlined fontSize="small" />
            </IconButton>
          </Stack>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.bic')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.bic}
              </Typography>
              <IconButton onClick={handleCopy(bankTransferData.bic)} className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid container gap={2}>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.iban')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.iban}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.iban)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md="auto">
            <Typography className={classes.termsContainerKey}>
              {t('deposit.bankTransfer.accountName')}
            </Typography>
            <Stack direction="row" gap={1}>
              <Typography className={classes.termsContainerValue}>
                {bankTransferData.accountName}
              </Typography>
              <IconButton
                onClick={handleCopy(bankTransferData.accountName)}
                className={classes.copyButton}>
                <FileCopyOutlined fontSize="small" />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} md="auto">
          <Typography className={classes.termsContainerKey}>
            {t('deposit.bankTransfer.referenceKey')}
          </Typography>
          <Typography className={classes.termsContainerValue}>
            {t('deposit.bankTransfer.referenceValue')}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default BankTransferCredentialsSepa;
