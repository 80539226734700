import { get } from 'lodash';

import { ACCOUNT_STATUSES, GP_COPY_PLATFORMS } from 'constants/index';

export const isAccountAvailableForCopy = (account) => {
  const { status, isLive, isArchived, platform } = account;

  const isArchivedStatus = get(isArchived, 'status', false);
  const isDeclined = status === ACCOUNT_STATUSES.REJECTED;
  const isRejectedAccount = isDeclined || isArchivedStatus;

  return !isRejectedAccount && !!isLive && GP_COPY_PLATFORMS.includes(platform);
};

const allowAccountForCopy = (
  allowCopyAccount, // user permission
  isUserVerified,
  account,
  gpCopyAccounts,
  copyFromAccount,
) => {
  const gpCopyAccount = gpCopyAccounts?.find(
    (tsAccount) =>
      tsAccount.accountId === account._id &&
      (tsAccount.isFollower || (!copyFromAccount && tsAccount.isLead)),
  );

  return allowCopyAccount && isUserVerified && isAccountAvailableForCopy(account) && !gpCopyAccount;
};

//check if the account can be a follower
export const allowToCopyTo = (allowCopyAccount, userIsVerified, account, gpCopyAccounts) => {
  return allowAccountForCopy(allowCopyAccount, userIsVerified, account, gpCopyAccounts, false);
};

// check if the account can be a lead and copy trades
export const allowToCopyFrom = (allowCopyAccount, userIsVerified, account, gpCopyAccounts) => {
  return allowAccountForCopy(allowCopyAccount, userIsVerified, account, gpCopyAccounts, true);
};
