import { combineActions, handleActions } from 'redux-actions';

import * as actionCreators from '../actions';
const defaultState = {
  withdraw: {
    allowed: [],
  },
  deposit: {
    allowed: [],
  },
};

export default handleActions(
  {
    [combineActions(actionCreators.getPaymentsPermissionsSuccess)](state, action) {
      return {
        ...state,
        ...action.response.data,
      };
    },
  },
  defaultState,
);
