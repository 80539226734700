import PropTypes from 'prop-types';
import React from 'react';

import TPCurrency from 'components/TP-UI/TPCurrency';
import TPGrid from 'components/TP-UI/TPGrid';
import TPTypography from 'components/TP-UI/TPTypography';
import { CURRENCY } from 'constants/index';
import formatAmount from 'utils/formatAmount';

import styles from './styles';

const CURRENCIES = Object.values(CURRENCY).reduce((acc, next) => {
  const { value } = next;

  acc[value] = { ...next };

  return acc;
}, {});

const AccountWithCurrency = (props) => {
  const {
    accountNickname = '',
    currency = '',
    mt4login = '',
    balance = '',
    hideBalance,
    hideFlag,
  } = props;

  const { flag, label } = CURRENCIES[currency] || {};
  const currencyAvailable = flag || label;

  return (
    <TPGrid
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      wrap="nowrap"
      className={styles.container}>
      <TPGrid item container direction="column">
        <TPTypography truncated lineClamp={1} variant={hideBalance ? 'body1' : 'body2'}>
          {mt4login} {accountNickname}
        </TPTypography>
        {hideBalance ? null : (
          <TPTypography truncated lineClamp={1} variant="body3" color="secondary">
            {formatAmount(balance, currency)}
          </TPTypography>
        )}
      </TPGrid>
      {currencyAvailable && !hideFlag ? (
        <TPGrid item>
          <TPCurrency flag={flag} label={label} />
        </TPGrid>
      ) : null}
    </TPGrid>
  );
};

AccountWithCurrency.propTypes = {
  accountNickname: PropTypes.string,
  currency: PropTypes.string,
  mt4login: PropTypes.string,
  hideBalance: PropTypes.bool,
  hideFlag: PropTypes.bool,
};

export default AccountWithCurrency;
