import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { Routes } from '../../constants/routeConstants';
import { LOGOUT_REASON, RETURN_URL_STORAGE_KEY } from '../../modules/auth/constants';
import { getIsImpersonation } from '../../modules/auth/selectors';
import { closeModal } from 'modules/common/CustomModal/actions';

/**
 * LocationHandler component ensures that certain actions are taken when the location changes
 */
const LocationHandler = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const prevLocationRef = useRef(location);
  const isImpersonation = useSelector(getIsImpersonation);
  const prevIsImpersonation = useRef(isImpersonation);

  const modals = useSelector((state) => state.modal);
  // temp for testing purposes
  // sessionStorage.setItem(
  //   RETURN_URL_STORAGE_KEY,
  //   JSON.stringify({ pathname: '/accounts/list', search: '', hash: '' }),
  // );

  useEffect(() => {
    if (location.pathname !== prevLocationRef.current.pathname) {
      if (
        matchPath(location.pathname, Routes.SIGNIN) &&
        !matchPath(prevLocationRef.current?.pathname, Routes.SIGNUP) &&
        !location.state?.cleanupStore &&
        (!location.state || location.state?.reason !== LOGOUT_REASON.USER_ACTION) &&
        !prevIsImpersonation.current
      ) {
        sessionStorage.setItem(RETURN_URL_STORAGE_KEY, JSON.stringify(prevLocationRef.current));
      }
      prevIsImpersonation.current = isImpersonation;

      Object.entries(modals).forEach(([key, value]) => {
        if (value) {
          dispatch(closeModal(key));
        }
      });

      prevLocationRef.current = location;
    }
  }, [location, dispatch, modals, isImpersonation]);

  return null;
};

export default LocationHandler;
