import { useUsersLocation } from 'hooks/useUsersLocation';
import {
  getIsAuthenticated,
  getIsFirstVisit,
  getUserRole,
  getUserHub,
  getUserCountry,
  getUserProStatus,
  getUserId,
  getIsAdminRole,
  getIsManagerRole,
} from 'modules/auth/selectors';
import { hideMessage } from 'modules/common/HideMessages/actions';
import { getHiddenMessage } from 'modules/common/HideMessages/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../components/MainLayout';
import ErrorBoundary from 'modules/errorBoundary';
import { showLocationRequest } from 'modules/common/Segment/actions';

const MainLayoutContainer = ({
  title,
  warning,
  mobileContent,
  desktopContent,
  contentClassName,
  headerClassName,
  titleClassName,
  isPublic,
  children,
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isFirstVisit = useSelector(getIsFirstVisit);
  const hideWarning = useSelector(getHiddenMessage.bind(null, warning));
  const userRole = useSelector(getUserRole);
  const isAdmin = useSelector(getIsAdminRole);
  const isManager = useSelector(getIsManagerRole);
  const hub = useSelector(getUserHub);
  const userCountry = useSelector(getUserCountry);
  const isPro = useSelector(getUserProStatus);
  const userId = useSelector(getUserId);
  const usersCurrentLocation = useUsersLocation();
  const [prevPath, setPrevPath] = useState(null);

  const handleHideMessage = useCallback(
    (data) => {
      dispatch(hideMessage(data));
    },
    [dispatch],
  );

  const handleLocationChange = useCallback(
    (data) => {
      if (isAuthenticated && !isAdmin && !isManager) {
        dispatch(showLocationRequest(data));
      }
    },
    [dispatch, isAdmin, isManager, isAuthenticated],
  );

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (usersCurrentLocation.location && prevPath !== usersCurrentLocation.location) {
      handleLocationChange({ ...usersCurrentLocation, userId });

      setPrevPath(usersCurrentLocation.location);
    }
  }, [prevPath, usersCurrentLocation, userId, handleLocationChange]);

  return (
    <ErrorBoundary message="Sorry! There has been an issue and we can't display your page at the moment. Please contact support for assistance if you need this information">
      <MainLayout
        title={title}
        warning={hideWarning ? null : warning}
        mobileContent={mobileContent}
        desktopContent={desktopContent}
        contentClassName={contentClassName}
        headerClassName={headerClassName}
        titleClassName={titleClassName}
        isAuthenticated={isAuthenticated}
        isPublic={isPublic}
        hideMessage={handleHideMessage}
        userRole={userRole}
        hub={hub}
        userCountry={userCountry}
        isPro={isPro}
        isFirstVisit={isFirstVisit}>
        {children}
      </MainLayout>
    </ErrorBoundary>
  );
};

export default MainLayoutContainer;
